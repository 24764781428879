import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Autocomplete from '@mui/joy/Autocomplete';
import Grid from '@mui/joy/Grid';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Checkbox from '@mui/joy/Checkbox';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CloseIcon from '@mui/icons-material/Close';

export default function TaskInitializing (props) {
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};
    const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
    const labelStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'12px',alignSelf:'center',lineHeight:1};
    const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'14px',color:'black',};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', maxWidth:'500px', textOverflow: 'ellipsis', overflow: 'hidden',lineHeight:1};
    const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};
    const formControlStyle = { my: 0.2 };

	const TaskInitializing = () => {
		const headers =[
			{id:'acSn',name:'AC_SN'}, 
			{id:'task',name:'Task'},
			{id:'remarks',name:'Remarks'},
			{id:'taskTitle',name:'Task_Title'},
			{id:'taskType',name:'Task_Type'},
			{id:'mnhr',name:'MNHR'},
			{id:'effective',name:'Effective'},
			{id:'status',name:'Status'},
			{id:'fhCompl',name:'FH_Compl'},
			{id:'fhNextDue',name:'FH_Next_Due'},
			{id:'fcCompl',name:'FC_Compl'},
			{id:'fcNextDue',name:'FC_Next_Due'},
			{id:'dateCompl',name:'Date_Compl'},
			{id:'dateNextDue',name:'Date_Next_Due'},
			{id:'iniId',name:'Ini_ID'},
		]

		const [showFilters, setShowFilters] = React.useState(false)
		const [data, setData] = React.useState([])
		const [filteredData, setFilteredData] = React.useState([]);
		const [filters, setFilters] = React.useState<{id:number, ata:string, task:string, basicTask:string, jic:string, taskTitle:string, taskDescription:string, taskType:string, taskEffectivity:string, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string,completedByComponentReplacement:string, ipcPositions:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string}>();
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRow, setSelectedRow] = React.useState({id:0});

		//Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskInitializedAlready(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

		//Фильтрация данных
		React.useEffect(() => {
			let filtered = data;
			for (const key in filters) {
				if(filters[key] != ''){
					filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
				}
			}
			setFilteredData(filtered);
		}, [data, filters]);

		//Изменение фильтров
		const handleChange = (property, value) => {
			setFilters({ ...filters, [property]: value });
		};

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = filteredData
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setFilteredData(filtered)
			setSort({lable:property, order:value})
		};

		//Проверка наличия фильтров
		const checkFilters = () => {
			if (filters === undefined) {
				return true;
			}
		
			// Проверяем, все ли свойства объекта пустые
			return Object.values(filters).every(value => 
				value === '' || value === null || value === undefined || 
				(typeof value === 'number' && value === 0)
			);
		}

		const checkRowColor = (row,index) => {
			if(selectedRow.acReg === row.acReg) return '#c0c0ff'
			if(index % 2 === 0){
				return '#EDF2F7'
			} else {
				return 'white'
			}
		}

		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.acReg === selectedRow.acReg){
				setSelectedRow({acReg:''});
			} else {
				setSelectedRow(row);
			}  
		};

		return(
			<Box
				sx={{
					height:'100%',
					width:'100%',
					display:'grid',
					gridTemplateColumns:'50% 50%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'14px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16,
							top:0
						}}
					>
						Task Initializing:
					</Typography>
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={labelStyle}>Task Num.:</FormLabel>
							<Input
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
                                    '& input': {
                                        textAlign: 'center',
                                    }
								}} 
                                value ={props.selectedForInitializingTask.task || ''}
							/>
						</FormControl>
					</Box>
					<Box
						//onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							//bgcolor:checkFilters()?'transparent':'#DDE7EE',
							mx:'16px',
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '24px',
								transition: '0.2s',
								//transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 90px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'0px',
							mb:'10px'
						}}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '22px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.acReg != 0 ? 'bold' : 'normal'
											}}
										>
											AC_Reg
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												//value={filters?.id || ''}
												//onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.ataCode || ''}
												//onChange={(event) => handleChange('ataCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.title || ''}
												//onChange={(event) => handleChange('title', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											{/*<Autocomplete
												options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
												onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
												value={{ label: filters?.acFamily || ''}}
												sx={filtersStyle}
											/>*/}
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.acReg}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.acReg === row.acReg ? '#c0c0ff' : '#c6c3c6'}}>{row.acReg}</td>
										<td style={rowStyle}>{row.acSn}</td>
										<td style={rowStyle}>{row.task}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.taskTitle}</td>
										<td style={rowStyle}>{row.taskType}</td>
										<td style={rowStyle}>{row.mnhr}</td>
										<td style={rowStyle}>{row.effective}</td>
										<td style={rowStyle}>{row.status}</td>
										<td style={rowStyle}>{row.fhCompl}</td>
										<td style={rowStyle}>{row.fhNextDue}</td>
										<td style={rowStyle}>{row.fcCompl}</td>
										<td style={rowStyle}>{row.fcNextDue}</td>
										<td style={rowStyle}>{row.dateCompl}</td>
										<td style={rowStyle}>{row.dateNextDue}</td>
										<td style={rowStyle}>{row.iniId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
                <Box
                    sx={{position:'relative'}}
                >
                    <TaskInitializingEditore/>
                </Box>
			</Box>
		);
	}

    const TaskInitializingEditore = () => {
        //Стили
        const headers =[
            {id:'sel',name:'SEL'}, 
			{id:'acSn',name:'AC_SN'}, 
			{id:'acFamily',name:'AC_Family'},
			{id:'effective',name:'Effective'},
			{id:'operatorCodeIcao',name:'Operator_Code_ICAO'},
			{id:'taskEffectivity',name:'Task_Effectivity'},
			{id:'note',name:'Note'},
			{id:'isApu',name:'isAPU'},
		]

		const [showFilters, setShowFilters] = React.useState(false)
		const [data, setData] = React.useState([])
		const [filteredData, setFilteredData] = React.useState([]);
		const [filters, setFilters] = React.useState<{id:number, ata:string, task:string, basicTask:string, jic:string, taskTitle:string, taskDescription:string, taskType:string, taskEffectivity:string, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string,completedByComponentReplacement:string, ipcPositions:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string}>();
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRows, setSelectedRows] = React.useState([]);
        const [editedData, setEditedData] = React.useState({});
        const [taskIdList, setTaskIdList] = React.useState([]);
        const [taskTypeList, setTaskTypeList] = React.useState([]);
        const [taskEffList, setTaskEffList] = React.useState([]);
        const [mainZoneList, setMainZoneList] = React.useState([]);
        const [mrbCodeList, setMrbCodeList] = React.useState([]);
        const [isAtaValid, setIsAtaValid] = React.useState(true);
        const [meta, setMeta] = React.useState([{name:'',max:0}]);

        //Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskAircraftsCanBeInitialized(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

		//Фильтрация данных
		React.useEffect(() => {
			let filtered = data;
			for (const key in filters) {
				if(filters[key] != ''){
					filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
				}
			}
			setFilteredData(filtered);
		}, [data, filters]);

		//Изменение фильтров
		const handleChange = (property, value) => {
			setFilters({ ...filters, [property]: value });
		};

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = filteredData
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setFilteredData(filtered)
			setSort({lable:property, order:value})
		};

		//Проверка наличия фильтров
		const checkFilters = () => {
			if (filters === undefined) {
				return true;
			}
		
			// Проверяем, все ли свойства объекта пустые
			return Object.values(filters).every(value => 
				value === '' || value === null || value === undefined || 
				(typeof value === 'number' && value === 0)
			);
		}

		const checkRowColor = (row,index) => {
            if(selectedRows.indexOf(row?.acReg) > -1) return '#c0c0ff'
            if(index % 2 === 0){
                return '#EDF2F7'
            } else {
                return 'white'
            }
        }

		//Выбор строки
        const handleRowSelection = (row) => {
            const arr = selectedRows;
            const index = arr.indexOf(row.acReg);

            if (index > -1) {
                arr.splice(index, 1);
            } else {
                arr.push(row.acReg);
            }
            setSelectedRows([...arr]);
        };
        
        return (
            <Box
                sx={{height:'100%'}}
            >
                <Typography 
                    sx={{
                        fontSize:'14px',
                        fontWeight:'bold',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        ml:1
                    }}
                >
                    Select Aircrafts to be Initializing:
                </Typography>
                <Box
                    sx={{
                        position:'absolute',
                        height:`100%`,
                        width:'100%',
                        top:'0px',
                        overflow:'auto',
                    }}
                >
                    <Box
                        id='filters'
                        sx={{
                            display:'flex',
                            flexWrap: 'nowrap',
                            gap: 0.5,
                            alignItems:'flex-end',
                            mt:'20px',
                            mx:'16px',
                        }}
                    >
                        <FormControl>
                            <FormLabel sx={labelStyle}>Task Num.:</FormLabel>
                            <Input
                                //value={filters?.task || ''}
                                //onChange={(event) => handleChange('task', event.target.value)}
                                sx={{...inputStyle, width:200}}
                            />
                        </FormControl>
                    </Box>
                    <Box
                        //onClick={() => setShowFilters(!showFilters)}
                        sx={{
                            border:'1px solid black',
                            borderBottom:'none',
                            height:'24px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            //bgcolor:checkFilters()?'transparent':'#DDE7EE',
                            mx:'16px',
                        }}
                    >
                        <ArrowDropDownIcon 
                            sx={{
                                fontSize: '24px',
                                transition: '0.2s',
                                //transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
                            }}
                        />
                    </Box>
                    <Sheet
                        id='tablesSheet'
                        variant="outlined"
                        sx={{
                            position:'absolute',
                            width: `calc(100% - 32px)`,
                            height:`40%`,
                            borderRadius: '0px',
                            flexShrink: 1,
                            overflow: 'auto',
                            border: '1px solid black',
                            bgcolor:'#848284',
                            mx:'16px',
                            mt:'0px',
                            mb:'10px'
                        }}
                    >
                        <Table
                            id='table'
                            borderAxis="both"
                            stickyHeader
                            hoverRow
                            sx={{
                                '--TableCell-headBackground': '#d6d3ce',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '1px',
                                '--TableCell-height': '22px',
                                '--TableCell-paddingX': '5px',
                                '--TableCell-borderColor': '#939293',
                                tableLayout: 'auto', 
                                width:'auto',
                                borderRadius:'0px'
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={headerStyle}>
                                        <Typography
                                            onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
                                            endDecorator={<ArrowDropDownIcon />}
                                            sx={{
                                                '& svg': {
                                                    color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
                                                    transition: '0.2s',
                                                    transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                },
                                                fontWeight:filters?.acReg != 0 ? 'bold' : 'normal'
                                            }}
                                        >
                                            AC_Reg
                                        </Typography>
                                    </th>
                                    {headers.map((row) => (
                                        <th style={headerStyle}>
                                            <Typography
                                                onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
                                                endDecorator={<ArrowDropDownIcon />}
                                                sx={{
                                                    '& svg': {
                                                        color:sort.lable === row.id ? '#636b74' : 'transparent',
                                                        transition: '0.2s',
                                                        transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                    },
                                                    fontWeight:filters?.[row.id] ? 'bold' : 'normal'
                                                }}
                                            >
                                                {row.name}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                                {showFilters && (
                                    <tr>
                                        <th style={{padding:0}}>
                                            <Input
                                                //value={filters?.id || ''}
                                                //onChange={(event) => handleChange('id', event.target.value)}
                                                type="number"
                                                slotProps={{
                                                    input: {
                                                        step: 1,
                                                        min:0
                                                    }
                                                }}
                                                sx={filtersStyle}
                                            />
                                        </th>
                                        <th style={{padding:0}}>
                                            <Input
                                                //value={filters?.ataCode || ''}
                                                //onChange={(event) => handleChange('ataCode', event.target.value)}
                                                sx={filtersStyle}
                                            />
                                        </th>
                                        <th style={{padding:0}}>
                                            <Input
                                                //value={filters?.title || ''}
                                                //onChange={(event) => handleChange('title', event.target.value)}
                                                sx={filtersStyle}
                                            />
                                        </th>
                                        <th style={{padding:0}}>
                                            {/*<Autocomplete
                                                options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
                                                onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
                                                value={{ label: filters?.acFamily || ''}}
                                                sx={filtersStyle}
                                            />*/}
                                        </th>
                                        <th style={{backgroundColor:'#d6d3ce'}}></th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {filteredData.length == 0 && (
                                    <th colSpan={999}>
                                        <td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
                                    </th>
                                )}
                                {filteredData.map((row, index) => (
                                    <tr
                                        key={row.acReg}
                                        onClick={() => handleRowSelection(row)}
                                        style={{ 
                                            backgroundColor: checkRowColor(row, index),
                                            color:'black',
                                        }}
                                    >
                                        <td style={{...rowStyle, backgroundColor: '#c6c3c6'}}>{row.acReg}</td>
                                        <td style={{...rowStyle, textAlign:'center' }}>
                                            <Checkbox 
                                                variant="outlined"
                                                color="neutral"
                                                sx={{
                                                    fontSize:'12px',
                                                    '--Checkbox-size':'15px',
                                                    gap:0.4,
                                                    '& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
                                                }}
                                                checked={selectedRows.indexOf(row?.acReg) > -1}
                                            />
                                        </td>
                                        <td style={rowStyle}>{row.acSn}</td>
                                        <td style={rowStyle}>{row.acFamily}</td>
                                        <td style={rowStyle}>{row.effective}</td>
                                        <td style={rowStyle}>{row.operatorCodeIcao}</td>
                                        <td style={rowStyle}>{row.taskEffectivity}</td>
                                        <td style={rowStyle}>{row.note}</td>
                                        <td style={rowStyle}>{row.isApu.toString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Sheet>
                    <Box 
                        sx={{
                            position:'absolute',
                            width: `calc(100% - 32px)`,
                            height:`42%`,
                            border:'1px solid black',
                            top:`calc(40% + 107px)`,
                            mx:'16px'
                        }}
                    >
                        <Box 
                            sx={{
                                display:'flex', 
                                position:'absolute',
                                top:-12,
                                left:5
                            }}
                        >
                            <Typography
                                sx={{
                                    bgcolor:'#d6d3ce',
                                    fontWeight:'600',
                                    color:'black',
                                    fontSize:14
                                }}
                            >
                                Initializaizing Date:
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height:'100%',
                                display:'grid',
                                gridTemplateRows:`45px 42px 25% auto`,
                                gridTemplateColumns:'20% 20% 20% 20% 20%',
                                p:0.5,

                            }}
                        >
                            <Box
                                sx={{
                                    pt:1.2,
                                }}
                            >
                                <RadioGroup orientation="vertical" >
                                    <Radio 
                                        value="compl" 
                                        label="Compl.:" 
                                        variant="outlined" 
                                        color='neutral'
                                        sx={{
                                            '--Radio-size':'14px',
                                            gap:0.5,
                                            fontSize:14
                                        }}
                                    />
                                    <Radio 
                                        value="due" 
                                        label="Due" 
                                        variant="outlined" 
                                        color='neutral'
                                        sx={{
                                            '--Radio-size':'14px',
                                            gap:0.5,
                                            fontSize:14,
                                            mt:1
                                        }}
                                    />
                                </RadioGroup>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5,
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>Compl. Date:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Compl. FH:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Compl. FC:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>Latest Found Date:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box/>
                            <Box
                                sx={{
                                    px:0.5,
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total Date:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total FH:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total FC:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box/>
                            <Box>
                                <Tooltip title='Whichever Comes Last (Intervals Value)'>
									<Checkbox 
										label='Not Effective:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.whicheverLast === 'Y'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
                            </Box>
                            <Box
                                sx={{
                                    gridColumnStart:2,
                                    gridColumnEnd:6,
                                }}
                            >
                                <Textarea 
                                    maxRows={1}
                                    sx={{...inputStyle,color:'#000078', width:'100%', height:'100%'}}
                                    value={editedData?.taskTitle || ''}
                                    onChange={(event) => handleChange('taskTitle', event.target.value)}
                                />
                            </Box>
                            <Box
                                sx={{
                                    gridColumnStart:1,
                                    gridColumnEnd:5,
                                    pt:0.5
                                }}
                            >
                                <Textarea 
                                    maxRows={1}
                                    sx={{...inputStyle,color:'#000078', width:'100%', height:'100%'}}
                                    value={editedData?.taskTitle || ''}
                                    onChange={(event) => handleChange('taskTitle', event.target.value)}
                                />
                            </Box>
                        </Box>     
                    </Box>
                    {/** 
                    <Grid container spacing={0} sx={{ flexGrow: 1 }}>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Task ID:</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    options={taskIdList?.map(data=> ({ label: data.task }))}
                                    value={{ label: editedData.task ?? ''}}
                                    sx={inputStyle}
                                    //onChange={(event, value) => handleChange('task', value ? value.label : '')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Basic Task:</FormLabel>
                                <Input  
                                    sx={inputStyle} 
                                    value={editedData?.basicTask ?? ''}
                                    //onChange={(event) => handleChange('basicTask', event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={1}></Grid>
                        <Grid xs={3}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>ATA:</FormLabel>
                                <Box
                                    sx={{display:'flex', alignItems:'center', justifyContent:'center'}}
                                >
                                    <Input  
                                        sx={inputStyle} 
                                        //value={editedData?.ata?.split('-')[0] || ''}
                                        //onChange={(event) => handleChange('title', event.target.value)}
                                    />
                                    -
                                    <Input  
                                        sx={inputStyle} 
                                        //value={editedData?.ata?.split('-')[1] || ''}
                                        //onChange={(event) => handleChange('title', event.target.value)}
                                    />
                                    -
                                    <Input  
                                        sx={inputStyle} 
                                        //value={editedData?.ata?.split('-')[2] || ''}
                                        //onChange={(event) => handleChange('title', event.target.value)}
                                    />
                                </Box>
                            </FormControl>
                        </Grid>

                        <Grid xs={12}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Task Title:</FormLabel>
                                <Textarea 
                                    maxRows={1}
                                    defaultValue={props.selectedRow.description}
                                    sx={{...inputStyle,color:'#000078'}}
                                    value={editedData?.taskTitle || ''}
                                    onChange={(event) => handleChange('taskTitle', event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={12}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Task Description:</FormLabel>
                                <Textarea 
                                    maxRows={1}
                                    defaultValue={props.selectedRow.description}
                                    sx={{...inputStyle,color:'#000078', height:60}}
                                    value={editedData?.taskDescription || ''}
                                    onChange={(event) => handleChange('taskDescription', event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={2}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Task Type:</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    options={taskTypeList?.map(data=> ({ label: data }))}
                                    value={{ label: editedData.taskType?? ''}}
                                    sx={inputStyle}
                                    onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle} required>
                                <FormLabel sx={labelStyle}>Task Effectivity:</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    options={taskEffList?.map(data=> ({ label: data.acConfigurationDescription }))}
                                    value={{ label: editedData.taskEffectivity ?? ''}}
                                    sx={inputStyle}
                                    onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={2}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>MNHR:</FormLabel>
                                <Input  
                                    sx={inputStyle} 
                                    value={editedData?.nnhr?? ''}
                                    onChange={(event) => handleChange('nnhr', event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>JIC:</FormLabel>
                                <Textarea 
                                    maxRows={1}
                                    defaultValue={props.selectedRow.description}
                                    sx={{...inputStyle,color:'#000078'}}
                                    value={editedData?.jic || ''}
                                    onChange={(event) => handleChange('jic', event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={2}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>Main Zone:</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    options={mainZoneList?.map(data=> ({ label: data.mainZone }))}
                                    value={{ label: editedData.mainZone ?? ''}}
                                    sx={inputStyle}
                                    onChange={(event, value) => handleChange('mainZone', value ? value.label : '')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>Additional Zones:</FormLabel>
                                <Input  
                                    sx={inputStyle} 
                                    value={editedData?.zones ?? ''}
                                    onChange={(event) => handleChange('zones', event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={2}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>MRB Code:</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    options={mrbCodeList.map(data=> ({ label: data.mrbCode }))}
                                    value={{ label: editedData.mrbCode ?? ''}}
                                    sx={inputStyle}
                                    onChange={(event, value) => handleChange('mrbCode', value ? value.label : '')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl sx={formControlStyle}>
                                <FormLabel sx={labelStyle}>NOTE:</FormLabel>
                                <Textarea 
                                    maxRows={1}
                                    defaultValue={props.selectedRow.description}
                                    sx={{...inputStyle,color:'#000078'}}
                                    value={editedData?.note || ''}
                                    onChange={(event) => handleChange('note', event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={12}>
                            <Tabs
                                //value={selectedTab}
                                //onChange={(event, newValue) => handleChangeTab(newValue)}
                                defaultValue="Int"
                                sx={{
                                    flexDirection: 'column',
                                    bgcolor:'#d6d3ce',
                                    whiteSpace: 'nowrap',
                                    ml:0.6,
                                    '--Tabs-spacing': '5px'
                                }}
                            >
                                <TabList >
                                    <Tooltip title='Interval'>
                                        <Tab value="Int" sx={tabStyle}>
                                            Interval
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Start Threshold'>
                                        <Tab value="ST" sx={tabStyle}>
                                            Start Threshold
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Finish Threshold'>
                                        <Tab value="FT" sx={tabStyle}>
                                            Finish Threshold
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Tolerance Method'>
                                        <Tab value="T" sx={tabStyle}>
                                            Tolerance
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Instructions'>
                                        <Tab value="Ins" sx={tabStyle}>
                                            Instructions
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='POST THRESHOLD INTERVALS FOR STRUCTURES AND ZONAL TASKS'>
                                        <Tab value="PT" sx={tabStyle}>
                                            Post Threshold
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Lower Utilization Maintenance Program'>
                                        <Tab value="LUMP" sx={tabStyle}>
                                            LUMP
                                        </Tab>
                                    </Tooltip>
                                </TabList>
                                <TabPanel value="Int" sx={{p:'0px'}}>
                                    <IntervalEditore/>
                                </TabPanel>
                                <TabPanel value="ST" sx={{p:'0px'}}>
                                    <StartThresholdEditore/>
                                </TabPanel>
                                <TabPanel value="FT" sx={{p:'0px'}}>
                                    <FinishThresholdEditore/>
                                </TabPanel>
                                <TabPanel value="T" sx={{p:'0px'}}>
                                    <ToleranceEditore/>
                                </TabPanel>
                                <TabPanel value="Ins" sx={{p:'0px'}}>
                                    <InstructionsEditore/>
                                </TabPanel>
                                <TabPanel value="PT" sx={{p:'0px'}}>
                                    <PostThresholdEditore/>
                                </TabPanel>
                                <TabPanel value="LUMP" sx={{p:'0px'}}>
                                    <LUMPEditore/>
                                </TabPanel>
                            </Tabs>
                        </Grid>
                        
                        <Grid xs={12}>
                            <Tabs
                                //value={selectedTab}
                                //onChange={(event, newValue) => handleChangeTab(newValue)}
                                defaultValue="DR"
                                sx={{
                                    flexDirection: 'column',
                                    bgcolor:'#d6d3ce',
                                    whiteSpace: 'nowrap',
                                    ml:0.6,
                                    mt:0.5,
                                    '--Tabs-spacing': '5px'
                                }}
                            >
                                <TabList >
                                    <Tooltip title='Document Reference'>
                                        <Tab value="DR" sx={tabStyle}>
                                            Doc. Ref
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Special Inspection is Required'>
                                        <Tab value="SI" sx={tabStyle}>
                                            Special Insp.
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Panels'>
                                        <Tab value="P" sx={tabStyle}>
                                            Panels
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Materials'>
                                        <Tab value="M" sx={tabStyle}>
                                            Materials
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Tools'>
                                        <Tab value="T" sx={tabStyle}>
                                            Tools
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='JIC Procedure'>
                                        <Tab value="JICP" sx={tabStyle}>
                                            JIC Procedure
                                        </Tab>
                                    </Tooltip>
                                    <Tooltip title='Job Card Control by'>
                                        <Tab value="C" sx={tabStyle}>
                                            Control
                                        </Tab>
                                    </Tooltip>
                                </TabList>
                                <TabPanel value="DR" sx={{p:'0px'}}>
                                    {!showTabsEditore ? (
                                        <DocRef/>
                                    ):(
                                        <DocRefEditor/>
                                    )}
                                    
                                </TabPanel>
                                <TabPanel value="SI" sx={{p:'0px'}}>
                                    {!showTabsEditore ? (
                                        <SpecialInsp/>
                                    ):(
                                        <SpecialInspEditor/>
                                    )}
                                </TabPanel>
                                <TabPanel value="P" sx={{p:'0px'}}>
                                    {!showTabsEditore ? (
                                        <Panels/>
                                    ):(
                                        <PanelsEditor/>
                                    )}
                                </TabPanel>
                                <TabPanel value="M" sx={{p:'0px'}}>
                                    <Materials/>
                                </TabPanel>
                                <TabPanel value="T" sx={{p:'0px'}}>
                                    <Tools/>
                                </TabPanel>
                                <TabPanel value="JICP" sx={{p:'0px'}}>
                                    <JIC/>
                                </TabPanel>
                                <TabPanel value="C" sx={{p:'0px'}}>
                                    <Controls/>
                                </TabPanel>
                            </Tabs>
                        </Grid>
                        {!showTabsEditore && (
                            <>
                            <Grid xs={6}>
                                <Box 
                                    sx={{
                                        position:'relative',
                                        border:'1px solid black',
                                        mt:1
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            display:'flex', 
                                            position:'absolute',
                                            top:-8,
                                            left:5
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                bgcolor:'#d6d3ce',
                                                fontWeight:'600',
                                                color:'black',
                                                fontSize:14,
                                                lineHeight:1
                                            }}
                                        >
                                            Associated Task:
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.5, px:1 }}>
                                        <Grid xs={1}/>
                                        <Grid xs={3}>
                                            <Typography
                                                sx={{
                                                    fontSize:14,
                                                    color:'black'
                                                }}
                                            >
                                                Filter Task:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={4}>
                                            <Input  
                                                sx={inputStyle} 
                                                value={editedData?.title ?? ''}
                                                onChange={(event) => handleChange('title', event.target.value)}
                                            />
                                        </Grid>
                                        <Grid xs={1}/>
                                        <Grid xs={3}>
                                            <Button
                                                //onClick={handleConfirm}
                                                color='neutral'
                                                sx={{
                                                    borderRadius:0,
                                                    height: 25, 
                                                    minHeight: 0,
                                                    bgcolor:'#d6d3ce',
                                                    color:'black',
                                                    border:'2px outset grey',
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </Grid>

                                        <Grid xs={12}>
                                            <Box
                                                sx={{
                                                    border:'1px solid #d2dbe4',
                                                    height:'86px',
                                                    mx:0.5,
                                                    mt:0.1,
                                                    borderRadius:'0px',
                                                    bgcolor:'white',
                                                    overflow:'auto'
                                                }}
                                            >
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    borderAxis="none"
                                                    stickyHeader
                                                    hoverRow
                                                    sx={{
                                                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                                        '--TableCell-paddingY': '1px',
                                                        "--TableCell-height": "24px",
                                                        '--TableCell-paddingX': '5px',
                                                        tableLayout: 'auto', 
                                                        width:'auto',
                                                        borderRadius:'0px'
                                                    }}
                                                >
                                                    <tbody>
                                                    
                                                        {moduleList.map((row) => (
                                                            <tr
                                                                key={row.id}
                                                                onClick={() => setSelectedGroupForModule(row)}
                                                                style={{ 
                                                                    backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
                                                                    color: selectedGroupForModule === row ? 'white' : 'black',
                                                                }}
                                                            >
                                                                <td style={rowStyle}>{row.id}</td>
                                                                <td style={rowStyle}>{row.groupName}</td>
                                                                <td style={rowStyle}>{row.groupSta}</td>
                                                                <td style={rowStyle}>{row.permission}</td>
                                                                <td style={rowStyle}>{row.activate}</td>
                                                                <td style={rowStyle}>{row.remarks}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid xs={6}>
                                <Box 
                                    sx={{
                                        position:'relative',
                                        border:'1px solid black',
                                        mt:1
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            display:'flex', 
                                            position:'absolute',
                                            top:-8,
                                            left:5
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                bgcolor:'#d6d3ce',
                                                fontWeight:'600',
                                                color:'black',
                                                fontSize:14,
                                                lineHeight:1
                                            }}
                                        >
                                            Related Task or EC:
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
                                        <Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
                                            <RadioGroup orientation="horizontal">
                                                <Radio 
                                                    value="Task" 
                                                    label="Task" 
                                                    variant="outlined" 
                                                    color='neutral'
                                                    sx={{
                                                        '--Radio-size':'14px',
                                                        gap:0.5,
                                                        fontSize:14
                                                    }}
                                                />
                                                <Radio 
                                                    value="EC" 
                                                    label="EC" 
                                                    variant="outlined" 
                                                    color='neutral'
                                                    sx={{
                                                        '--Radio-size':'14px',
                                                        gap:0.5,
                                                        fontSize:14
                                                    }}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            <Typography>
                                                Filter:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
                                            <Input  
                                                sx={inputStyle} 
                                                value={editedData?.title ?? ''}
                                                onChange={(event) => handleChange('title', event.target.value)}
                                            />
                                        </Grid>
                                        <Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
                                            <Button
                                                //onClick={handleConfirm}
                                                color='neutral'
                                                sx={{
                                                    borderRadius:0,
                                                    height: 25, 
                                                    minHeight: 0,
                                                    bgcolor:'#d6d3ce',
                                                    color:'black',
                                                    border:'2px outset grey',
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </Grid>

                                        <Grid xs={12}>
                                            <Box
                                                sx={{
                                                    border:'1px solid #d2dbe4',
                                                    height:'86px',
                                                    mx:0.5,
                                                    mt:0.1,
                                                    borderRadius:'0px',
                                                    bgcolor:'white',
                                                    overflow:'auto'
                                                }}
                                            >
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    borderAxis="none"
                                                    stickyHeader
                                                    hoverRow
                                                    sx={{
                                                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                                        '--TableCell-paddingY': '1px',
                                                        "--TableCell-height": "24px",
                                                        '--TableCell-paddingX': '5px',
                                                        tableLayout: 'auto', 
                                                        width:'auto',
                                                        borderRadius:'0px'
                                                    }}
                                                >
                                                    <tbody>
                                                        
                                                        {moduleList.map((row) => (
                                                            <tr
                                                                key={row.id}
                                                                onClick={() => setSelectedGroupForModule(row)}
                                                                style={{ 
                                                                    backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
                                                                    color: selectedGroupForModule === row ? 'white' : 'black',
                                                                }}
                                                            >
                                                                <td style={rowStyle}>{row.id}</td>
                                                                <td style={rowStyle}>{row.groupName}</td>
                                                                <td style={rowStyle}>{row.groupSta}</td>
                                                                <td style={rowStyle}>{row.permission}</td>
                                                                <td style={rowStyle}>{row.activate}</td>
                                                                <td style={rowStyle}>{row.remarks}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            </>
                        )}
                    </Grid>
                    */}
                </Box>
            </Box>
        )
    }

    const TaskActualInformation = () => {
		const headers =[
			{id:'acSn',name:'AC_SN'}, 
			{id:'task',name:'Task'},
			{id:'remarks',name:'Remarks'},
			{id:'taskTitle',name:'Task_Title'},
			{id:'taskType',name:'Task_Type'},
			{id:'mnhr',name:'MNHR'},
			{id:'effective',name:'Effective'},
			{id:'status',name:'Status'},
			{id:'fhCompl',name:'FH_Compl'},
			{id:'fhNextDue',name:'FH_Next_Due'},
			{id:'fcCompl',name:'FC_Compl'},
			{id:'fcNextDue',name:'FC_Next_Due'},
			{id:'dateCompl',name:'Date_Compl'},
			{id:'dateNextDue',name:'Date_Next_Due'},
			{id:'iniId',name:'Ini_ID'},
		]

		const [showFilters, setShowFilters] = React.useState(false)
		const [data, setData] = React.useState([])
		const [filteredData, setFilteredData] = React.useState([]);
		const [filters, setFilters] = React.useState<{id:number, ata:string, task:string, basicTask:string, jic:string, taskTitle:string, taskDescription:string, taskType:string, taskEffectivity:string, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string,completedByComponentReplacement:string, ipcPositions:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string}>();
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRow, setSelectedRow] = React.useState({id:0});

		//Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskInitializedAlready(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

		//Фильтрация данных
		React.useEffect(() => {
			let filtered = data;
			for (const key in filters) {
				if(filters[key] != ''){
					filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
				}
			}
			setFilteredData(filtered);
		}, [data, filters]);

		//Изменение фильтров
		const handleChange = (property, value) => {
			setFilters({ ...filters, [property]: value });
		};

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = filteredData
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setFilteredData(filtered)
			setSort({lable:property, order:value})
		};

		//Проверка наличия фильтров
		const checkFilters = () => {
			if (filters === undefined) {
				return true;
			}
		
			// Проверяем, все ли свойства объекта пустые
			return Object.values(filters).every(value => 
				value === '' || value === null || value === undefined || 
				(typeof value === 'number' && value === 0)
			);
		}

		const checkRowColor = (row,index) => {
			if(selectedRow.acReg === row.acReg) return '#c0c0ff'
			if(index % 2 === 0){
				return '#EDF2F7'
			} else {
				return 'white'
			}
		}

		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.acReg === selectedRow.acReg){
				setSelectedRow({acReg:''});
			} else {
				setSelectedRow(row);
			}  
		};

		return(
			<Box
				sx={{
					height:'100%',
					width:'100%',
					display:'grid',
					gridTemplateColumns:'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'14px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16,
							top:0
						}}
					>
						Task Initializing:
					</Typography>
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={labelStyle}>Task Num.:</FormLabel>
							<Input
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
                                    '& input': {
                                        textAlign: 'center',
                                    }
								}} 
                                value ={props.selectedForInitializingTask.task || ''}
							/>
						</FormControl>
					</Box>
					<Box
						//onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							//bgcolor:checkFilters()?'transparent':'#DDE7EE',
							mx:'16px',
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '24px',
								transition: '0.2s',
								//transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 90px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'0px',
							mb:'10px'
						}}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '22px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.acReg != 0 ? 'bold' : 'normal'
											}}
										>
											AC_Reg
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												//value={filters?.id || ''}
												//onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.ataCode || ''}
												//onChange={(event) => handleChange('ataCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.title || ''}
												//onChange={(event) => handleChange('title', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											{/*<Autocomplete
												options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
												onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
												value={{ label: filters?.acFamily || ''}}
												sx={filtersStyle}
											/>*/}
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.acReg}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.acReg === row.acReg ? '#c0c0ff' : '#c6c3c6'}}>{row.acReg}</td>
										<td style={rowStyle}>{row.acSn}</td>
										<td style={rowStyle}>{row.task}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.taskTitle}</td>
										<td style={rowStyle}>{row.taskType}</td>
										<td style={rowStyle}>{row.mnhr}</td>
										<td style={rowStyle}>{row.effective}</td>
										<td style={rowStyle}>{row.status}</td>
										<td style={rowStyle}>{row.fhCompl}</td>
										<td style={rowStyle}>{row.fhNextDue}</td>
										<td style={rowStyle}>{row.fcCompl}</td>
										<td style={rowStyle}>{row.fcNextDue}</td>
										<td style={rowStyle}>{row.dateCompl}</td>
										<td style={rowStyle}>{row.dateNextDue}</td>
										<td style={rowStyle}>{row.iniId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
			</Box>
		);
	}

    return (
		<Modal 
			open={props.showTaskInitializing} 
			disableRestoreFocus={true}
			disableEnforceFocus={true}
            sx={{backdropFilter:'blur(0px)'}}
		>
			<ModalDialog 
				variant="outlined" 
				role="alertdialog"
				sx={{
					p:0,
					borderRadius:0,
                    width:'60%',
                    height:'70%',
					gap:0,
                    minWidth:'1000px',
                    minHeight:'690px'
				}}
			>
				<DialogTitle
					sx={{
						py:0.5,
						pl:1,
						bgcolor:'#08246b',
						color:'white',
                        fontSize:'12px'
					}}
				>
                    Task Initializing-Actual Information
					<IconButton 
						onClick={() => props.setShowTaskInitializing(false)}
						sx={{
							mr:1,
							ml:'auto',
							height:'12px',
							minHeight:'0px',
							width:'12px',
							minWidth:'0px',
							color:'black'
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent 
					sx={{
						m:0,
						p:0
					}}
				>
					<Tabs
						//value={selectedTab}
						//onChange={(event, newValue) => handleChangeTab(newValue)}
						defaultValue="TI"
						sx={{
							flexDirection: 'column',
							bgcolor:'#d6d3ce',
							whiteSpace: 'nowrap',
							'--Tabs-spacing': '5px',
							height:'100%',
							width:'100%',
							m:0
						}}
					>
						<TabList >
							<Tab value="TI" sx={tabStyle}>
								Task-Initializing
							</Tab>
							<Tab value="TAI" sx={tabStyle}>
								Task-Actual Information
							</Tab>
						</TabList>
						<TabPanel value="TI" sx={{p:'0px'}}>
							<TaskInitializing/>
						</TabPanel>
						<TabPanel value="TAI" sx={{p:'0px'}}>
                            <TaskActualInformation/>
						</TabPanel>
					</Tabs>
				</DialogContent>
				<DialogActions
				
					sx={{
						bgcolor:'#d6d3ce',
						pt:0,
					}}
				>
					<Input 
						key='No'
						type = 'Button' 
						onClick={() => props.setShowConfirm(false)}
						autoFocus
						value='No'
						sx={{
							borderRadius:0,
							minHeight:0,
							minWidth:0,
							height:'26px',
							width:'70px',
							border:'2px solid black',
							bgcolor:'#d6d3ce',
							color:'black',
							m:1,
							'&:hover': {
								backgroundColor: '#a8a5e6',
							}
						}}         
					/>
					<Button
						//onClick={handleYes}
						color='neutral'
						sx={{
							borderRadius:0,
							minHeight:0,
							minWidth:0,
							height:'26px',
							width:'70px',
							border:'1px solid black',
							bgcolor:'#d6d3ce',
							color:'black',
							m:1,
							'&:hover': {
								backgroundColor: '#a8a5e6',
							}
						}}                            
					>
						Yes
					</Button>
				</DialogActions>
			</ModalDialog>
     	</Modal>
	);
}