import * as React from 'react';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import SettingsIcon from '@mui/icons-material/Settings';

export default function Spiner () {
    return (
		<Modal 
			open={true} 
			sx={{
				backdropFilter:'blur(0px)',
			}}
		>
			<ModalDialog
                variant="soft" 
				sx={{
					p:0,
					borderRadius:0,
                    bgcolor:'transparent',
                    boxShadow:'none',
					display:'flex',
					flexDirection:'row',
					gap:0,

				}}
			>
				<div
					style={{
						position:'relative'
					}}
				>
					<SettingsIcon 
						sx={{
							color:'#e6ffff',
							animation: 'rotate 2s linear infinite',
							height:'70px',
							width:'70px',
							'@keyframes rotate': {
								'0%': {
									transform: 'rotate(0deg)',
								},
								'100%': {
									transform: 'rotate(360deg)',
								},
							},
							position:'absolute',
							bottom:0,
							left:0
						}} 
					/>
					<SettingsIcon 
						sx={{
							color:'#e6ffff',
							animation: 'rotate 2s linear infinite reverse',
							height:'45px',
							width:'45px',
							'@keyframes rotate': {
								'0%': {
									transform: 'rotate(0deg)',
								},
								'100%': {
									transform: 'rotate(360deg)',
								},
							},
							position:'absolute',
							bottom:0,
							left:55
						}} 
					/>
				</div>
			</ModalDialog>
     	</Modal>
	);
}