import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';


import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Airplane from '@mui/icons-material/AirplanemodeActive';
import EngineeringIcon from '@mui/icons-material/Engineering';

import AMP from './components/AMP.tsx'
import Header from '../Components/Header.tsx';
import Find_Components from './components/Find_Components.tsx';
import AC_Maintenance_Data from './components/AC_Maintenance_Data.tsx'
import Actual_Structure from './components/Actual_Structure.tsx';

import URL from '../../../URL.js';

export default function Part_M() {
	document.body.style.backgroundColor = '#000042';
	document.title = "Part-M"
    const [window, setWindow] = React.useState('AS');
	const [openFilters, setOpenFilters] = React.useState(false);
	const [openMaintanaceData, setOpenMaintanaceData] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [permissions, setPermissions] = React.useState([{module:'',window:'',access:''}]);
	const [user, setUser] = React.useState({login:'', name:'', userSTA:''});
	const [selectedAC, setSelectedAC] = React.useState({id:'', asID: '', acReg:''});
	const [selectedTab, setSelectedTab] = React.useState ('AS')
	const handleChangeTab = (newValue) => {
		setSelectedTab(newValue);
	} 
	const buttons = [{id:'TLOG',title:'TLOG'},{id:'NRC',title:'NRC'},{id:'ACTimes',title:'A/C Times'},{id:'MaterialManagement',title:'Material Management'},{id:'Shortage',title:'Shortage'}]
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/ActualStructure`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setData(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setUser(result);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchUser();
	}, []);

	React.useEffect(() => {
		const fetchPermissions = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/Permissions?&filter=module eq 'Part-M'`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const result = await response.json();
				setPermissions(result.value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchPermissions();
	}, []);

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1400px' }}>
				<Header title='Part-M'/>
				<Box
					sx={{
						position:'relative',
						display: 'grid',
						gridTemplateColumns: '5fr 1fr',
						height:'86vh',
						width:'100%',
						mt:8,
						mx:2,
						gap:2
					}}
				>
					<Box 
						sx={{
							backgroundColor:'#848284',
							outline:'1px solid white',
							position:'relative',
							mt:2,
							display:'flex',
							justifyContent:'center',
							minHeight:'500px'
						}}
					>
						<Stack
						 	direction="row"
						 	spacing={6}
							sx={{
								height:'15%',
								width:'98%',
								position:'absolute',
								border: '1px solid white', 
								top:'2%',
								display:'flex',
								alignItems:'center',
								justifyContent:'space-between',
								pr:6
							}}
						>
							<Box
								sx={{
									position:'absolute',
									top:-11,
									left:10,
									fontSize:20,
									color:'black',
									bgcolor:'#848284',
									lineHeight:0.9
								}}
							>
								Active User:
							</Box>
							<FormControl
								sx={{width:'auto',minWidth:0}}
							>
								<FormLabel 
									sx={{
										alignSelf:'center',
										fontSize:'20px',
										margin:'0px',
										color:'black'
									}}
								>
									User ID:
								</FormLabel>
								<Input 
									disabled
									sx={{
										'--Input-paddingBlock':0,
										'--Input-paddingInline':0,
										'--variant-outlinedDisabledColor':'#000084',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
										borderRadius:'0px',
										backgroundColor:'#c6ffff',
										fontWeight:'bold',
										fontSize:'23px',
										minWidth:0,
									}} 
									value={user.login}
								/>
							</FormControl>
							<FormControl
								sx={{width:'100%',minWidth:0}}
							>
								<FormLabel 
									sx={{
										alignSelf:'center',
										fontSize:'20px',
										margin:'0px',
										color:'black'
									}}
								>
									User Name:
								</FormLabel>
								<Input 
									disabled
									sx={{
										'--Input-paddingBlock':0,
										'--Input-paddingInline':0,
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
										borderRadius:'0px',
										backgroundColor:'#e7e3e7',
										fontWeight:'bold',
										fontSize:'23px',
										width:'100%',
										'& input': {
											textAlign: 'center',
										}
									}} 
									value={user.name}
								/>
							</FormControl>
							<FormControl
								sx={{width:'auto',minWidth:0}}
							>
								<FormLabel 
									sx={{
										alignSelf:'center',
										fontSize:'20px',
										margin:'0px',
										color:'black'
									}}
								>
									User STA:
								</FormLabel>
								<Input 
									size="sm" 
									disabled
									sx={{
										'--Input-paddingBlock':0,
										'--Input-paddingInline':0,
										'--variant-outlinedDisabledColor':'#000084',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
										borderRadius:'0px',
										backgroundColor:'#c6ffff',
										fontWeight:'bold',
										fontSize:'23px',
										minWidth:0,
									}} 
									value={user.userSTA}
								/>
							</FormControl>
						</Stack>
						<Box 
							sx={{
								height:'79%',
								width:'98%',
								position:'absolute',
								top:'19%'
							}}
						>
							<Box 
								sx={{
									height:'100%',
									width:'49.5%',
									position:'absolute',
									top:'0'
								}}
							>
								<Tabs
									value={selectedTab}
									onChange={(event,newValue) => handleChangeTab(newValue)}
									sx={{
										flexDirection: 'column',
										bgcolor:'transparent',
										whiteSpace: 'nowrap',
										height:'100%',
									}}
								>
									<TabList 
										sx={{
											bgcolor:'#d6d3ce',
											lineHeight:0.9,
											'--ListItem-minHeight':0
										}}
									>
										<Tab 
											value="AS" 
											sx={{
												fontSize:14,
												fontWeight:'bold',
												color:'black'
											}}
										>
											{selectedTab === 'AS' && (<Airplane sx={{width:14}}/>)}Actual Structure
										</Tab>
										<Tab 
											value="AMP" 
											sx={{
												fontSize:14,
												fontWeight:'bold',
												color:'black'
											}}
										>
											{selectedTab === 'AMP' && (<Airplane sx={{width:14}}/>)}AMP-Maintenance Programm
										</Tab>
									</TabList>
									<TabPanel 
										value="AS" 
										sx={{
											position:'relative',
											p:'0px'
										}}
									>
										<Actual_Structure setOpenFilters={setOpenFilters} openFilters={openFilters}/>
									</TabPanel>
									<TabPanel 
										value="AMP" 
										sx={{
											position:'relative',
											p:'0px'
										}}
									>
										<AMP/>
									</TabPanel>
								</Tabs>
							</Box>
							<Box 
								sx={{
									height:'100%',
									width:'49.5%',
									position:'absolute',
									bottom:0,
									right:0,
									border:'1px solid white'
								}}
							>
								{openFilters ? (
									<Find_Components/>
								):(
									<Box sx={{height:'100%',width:'100%', display:'flex', alignItems:'center',justifyContent:'center'}}>
										<img 
											width='300px' 
											height='300px' 
											src="images/logo.svg" 
											alt="" 
										/>
									</Box>
								)}
							</Box>
						</Box>
					</Box>
					<Stack
						direction="column"
						justifyContent="flex-end"
						alignItems="center"
						spacing={2}
						height = '100%'
						sx={{pr:4, pl:3}}
					>
						{buttons.map((b) => {
							if (permissions?.find((obj) => obj?.window === b?.id)?.access === 'Full Control') {
								return (
									<Button
										key={b.title}
										variant="outlined"
										sx={{
										borderRadius: '0px',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
										width: '100%',
										height: '85px',
										backgroundColor: '#8482ff',
										fontSize: '30px',
										borderTop: '2px solid white',
										borderLeft: '2px solid white',
										color: 'black',
										}}
									>
										{b.title}
									</Button>
								);
							} else {
								return (
									<Box
										sx={{
											height: '85px',	
										}}
									/>
								)
							}
						})}
					</Stack>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
