import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

export default function Requirements(props) {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px', maxWidth:'500px', textOverflow: 'ellipsis', overflow: 'hidden',};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[
		{id:'ata',name:'ATA'}, 
		{id:'task',name:'TASK'},
		{id:'basicTask',name:'BASIC_TASK'},
		{id:'jic',name:'JIC'},
		{id:'taskTitle',name:'TASK_Title'},
		{id:'taskDescription',name:'TASK_Description'},
		{id:'taskType',name:'Task_Type'},
		{id:'taskEffectivity',name:'Task_Effectivity'},
		{id:'fhStart',name:'FH_Start'},
		{id:'fcStart',name:'FC_Start'},
		{id:'calendarStart',name:'Calendar_Start'},
		{id:'calendarValueStart',name:'Calendar_Value_Start'},
		{id:'docReferenceStart',name:'Doc_Reference_Start'},
		{id:'fhInterval',name:'FH_Interval'},
		{id:'fcInterval',name:'FC_Interval'},
		{id:'calendarInterval',name:'Calendar_Interval'},
		{id:'calendarValueInterval',name:'Calendar_Value_Interval'},
		{id:'docReferenceInterval',name:'Doc_Reference_Interval'},
		{id:'completedByComponentReplacement',name:'Completed_By_Component_Replacement'},
		{id:'ipcPositions',name:'IPC_Positions'},
		{id:'fhFinish',name:'FH_Finish'},
		{id:'fcFinish',name:'FC_Finish'},
		{id:'calendarFinish',name:'Calendar_Finish'},
		{id:'calendarValueFinish',name:'Calendar_Value_Finish'},
		{id:'docReferenceFinish',name:'Doc_Reference_Finish'},
		{id:'etops',name:'ETOPS'},
		{id:'etopsII',name:'ETOPS_II'},
		{id:'di',name:'DI'},
		{id:'cat3',name:'CAT_3'},
		{id:'cr',name:'CR'},
		{id:'cdccl',name:'CDCCL'},
		{id:'ali',name:'ALI'},
		{id:'ewis',name:'EWIS'},
		{id:'cpcp',name:'CPCP'},
		{id:'cmr',name:'CMR'},
		{id:'ad',name:'AD'},
		{id:'instructionReference',name:'Instruction_Reference'},
		{id:'apuUtilization',name:'APU_Utilization'},
		{id:'nonScheduled',name:'NON_Scheduled'},
	]

	const tasksHeaders =[
		{id:'checkDescription',name:'Check_Description'},
		{id:'checkType',name:'Check_Type'},
		{id:'fhStart',name:'FH_Start'},
		{id:'fcStart',name:'FC_Start'},
		{id:'calendarStart',name:'Calendar_Start'},
		{id:'calendarValueStart',name:'Calendar_Value_Start'},
		{id:'fhInterval',name:'FH_Interval'},
		{id:'fcInterval',name:'FC_Interval'},
		{id:'calendarInterval',name:'Calendar_Interval'},
		{id:'calendarValueInterval',name:'Calendar_Value_Interval'},
		{id:'fhFinish',name:'FH_Finish'},
		{id:'fcFinish',name:'FC_Finish'},
		{id:'calendarFinish',name:'Calendar_Finish'},
		{id:'calendarValueFinish',name:'Calendar_Value_Finish'},
	]

	const [data, setData] = React.useState<{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [tasksData, setTasksData] = React.useState([])
	const [filters, setFilters] = React.useState<{id:number, ata:string, task:string, basicTask:string, jic:string, taskTitle:string, taskDescription:string, taskType:string, taskEffectivity:string, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string,completedByComponentReplacement:string, ipcPositions:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string}>();
	const [intervalFilters, setIntervalFilters] = React.useState<{check:string, fh:number, fc:number, calendarKind:string, calendarValue:number, resourceOr:boolean}>();
	const [checkboxesFilters, setCheckboxesFilters] = React.useState<{apu:boolean, outOfMp:boolean}>();
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [checksList, setChecksList] = React.useState ([]);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	const [showTaskTable, setShowTaskTable] = React.useState(false)
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		props.setShowSpinner(value => value + 1);
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/View(ampId=${props?.selectedAmp?.id},taskFilter='${filters?.task || ''}',ata='${filters?.ata || ''}',taskDescription='${filters?.taskDescription || ''}',apu=${checkboxesFilters?.apu || false},taskType='${filters?.taskType || ''}',outOfMp=${checkboxesFilters?.outOfMp || false},jic='${filters?.jic || ''}',taskEffectivity='${filters?.taskEffectivity || ''}',checkfilter='${intervalFilters?.check || ''}',fhFilter=${intervalFilters?.fh || 0},fcFilter=${intervalFilters?.fc || 0},calendarKindFilter='${intervalFilters?.calendarKind || 'DY'}',calendarValueFilter=${intervalFilters?.calendarValue || 0},resourceOrFilter=${intervalFilters?.resourceOr || false},mandLim=false,tcInstruction=false,comp=false,nonSchelduled=false,checkRelated=false,checkAssosiated=false,postThreshold=false,fls56=false,fls75=false,deleted=false)`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
		props.setShowSpinner(value => value - 1);
	};

	//Запрос данных для таблицы Task - Checks Model
	React.useEffect(() => {
		const fetchTasksData = async () => {
			//props.setShowSpinner(value => value + 1);
			const taskId = props.selectedRows[props.selectedRows.length - 1]
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/InCheck(ampId=${props.selectedAmp?.id},taskId=${taskId})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setTasksData(result);
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		if(props?.selectedAmp?.id && props.selectedRows.length !== 0 && showTaskTable) {
			fetchTasksData()
		}
		else{
			setTasksData([])
		}
	}, [props.selectedRows, showTaskTable])

	//Запрос списка Checks
	React.useEffect(() => {
		const fetchChecks = async () => {
			props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks?$filter=ampid eq ${props?.selectedAmp?.id}&$select=check`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setChecksList(result.value);
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			props.setShowSpinner(value => value - 1);
		};
		if(props?.selectedAmp?.id) fetchChecks();
	}, [props?.selectedAmp])

	React.useEffect(() => {
		if(props?.selectedAmp?.id) fetchData();
	}, [props?.selectedAmp, checkboxesFilters]);

	//Выбор строки
	const handleRowSelection = (row) => {
		const arr = props.selectedRows;
		const index = arr.indexOf(row.id);

		if (index > -1) {
			arr.splice(index, 1);
		} else {
			arr.push(row.id);
		}
		props.setSelectedRows([...arr]);
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters(undefined);
		setIntervalFilters(undefined);
		setCheckboxesFilters(undefined);
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение фильтров interval
	const handleChangeInterval = (property, value) => {
		setIntervalFilters({ ...intervalFilters, [property]: value });
	};

	//Изменение фильтров checkboxes (No MP, APU)
	const handleChangeCheckboxes = (property, value) => {
		setCheckboxesFilters({ ...checkboxesFilters, [property]: value });
	};

	//Подтверждение Interval Filters
	const confirmIntervalFilters = () => {
		fetchData()
		setShowIntervalFilters(false)
	}

	//Закрытие окна Interval Filters
	const cancelIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Сброс Interval Filters
	const resetIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		if (filters === undefined) {
			return true;
		}
	
		// Проверяем, все ли свойства объекта пустые
		return Object.values(filters).every(value => 
			value === '' || value === null || value === undefined || 
			(typeof value === 'number' && value === 0)
		);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/FilteredXlsx(ampId=${props.selectedAmp.id},taskFilter='${filters?.task ?? ''}',ata='${filters?.ata ?? ''}',taskDescription='${filters?.taskDescription ?? ''}',apu=${checkboxesFilters?.apu ?? false},taskType='${filters?.taskType ?? ''}',outOfMp=${checkboxesFilters?.outOfMp ?? false},jic='${filters?.jic ?? ''}',taskEffectivity='${filters?.taskEffectivity ?? ''}',checkfilter='${intervalFilters?.check ?? ''}',fhFilter=${intervalFilters?.fh ?? 0},fcFilter=${intervalFilters?.fc ?? 0},calendarKindFilter='${intervalFilters?.calendarKind ?? 'DY'}',calendarValueFilter=${intervalFilters?.calendarValue ?? 0},resourceOrFilter=${intervalFilters?.resourceOr ?? true})`, 
		{
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'AmpMrList.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	const checkRowColor = (row,index) => {
		if(props.selectedRows.indexOf(row?.id) > -1) return '#c0c0ff'
		if(row.nonScheduled) return '#FFA2A0'
		if(row.etops || row.etopsII || row.cat3 || row.fls56fc || row.di || row.cr || row.cdccl || row.fls75fc || row.ali || row.ewis || row.cmr || row.cpcp || row.ad || row.conditional) return '#ffe3c6'
		if(index % 2 === 0){
			return '#EDF2F7'
		} else {
			return 'white'
		}
	}
	
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='tableArea'
				sx={{
					position:'relative',
					width:'100%'
				}}
			>
				<Typography 
					id='tableAreaTitle'
					sx={{ 
						position:'absolute',
						fontSize:'16px',
						fontWeight:'bold',
						color:'#000078',
						fontFamily:'Arial, sans-serif',
						left:16
					}}
				>
					Maintenance Requirements:
				</Typography>
				<Box
					id='buttons'
					sx={{
						display:'flex',
						alignItems:'center',
						justifyContent:'center',
						position:'absolute',
						right:16,
						height:40
					}}
				>
					<Tooltip title='Create Excel Report'>
						<IconButton
							variant='plain'
							onClick={createExcel}
							sx={{
								m:0,
							}}
						>
							<img 
								width='35px' 
								src="/images/svg/excel.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					<Tooltip title='Reset Filters'>
						<IconButton
							variant='plain'
							onClick={refreshFilters}
							sx={{
								m:0,
							}}
						>
							<img 
								width='35px' 
								src="/images/svg/refresh.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
				</Box>	
				<Box
					id='filters'
					sx={{
						display:'flex',
						flexWrap: 'nowrap',
						gap: 0.5,
						alignItems:'flex-end',
						mt:'40px',
						mx:'16px',
					}}
				>
					<FormControl>
						<FormLabel sx={lableStyle}>Task:</FormLabel>
						<Input
							value={filters?.task || ''}
							onChange={(event) => handleChange('task', event.target.value)}
							sx={{...inputStyle, width:200}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>ATA:</FormLabel>
						<Input
							value={filters?.ata || ''}
							onChange={(event) => handleChange('ata', event.target.value)}
							sx={{...inputStyle, width:70}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>Task Description:</FormLabel>
						<Input
							value={filters?.taskDescription || ''}
							onChange={(event) => handleChange('taskDescription', event.target.value)}
							sx={{...inputStyle, width:150}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel sx={lableStyle}>Type:</FormLabel>
						<Autocomplete
							options={[...new Set(data.map(data => data.taskType))].filter(row => row).map(taskType=> ({ label: taskType}))}
							sx={{...inputStyle, width:100}}
							onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
							value={{ label: filters?.taskType || ''}}
						/>
					</FormControl>
					<Box
						sx={{
							display:'flex',
							flexDirection:'column',
							mb:1,
							textOverflow: 'ellipsis', 
							overflow: 'hidden',
							width:'40px'
						}}
					>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: !intervalFilters ? 'flex':'none'
							}}
						>
							No Filter
						</Typography>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: intervalFilters?.check ? 'flex':'none'
							}}
						>
							Check:{intervalFilters?.check}
						</Typography>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: intervalFilters?.fh ? 'flex':'none',
							}}
						>
							FH:{intervalFilters?.fh}
						</Typography>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: intervalFilters?.fc ? 'flex':'none'
							}}
						>
							FC:{intervalFilters?.fc}
						</Typography>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: intervalFilters?.calendarKind && intervalFilters?.calendarValue ? 'flex':'none'
							}}
						>
							{intervalFilters?.calendarKind}:{intervalFilters?.calendarValue}
						</Typography>
						<Typography
							sx={{
								fontSize:10,
								lineHeight:1,
								display: intervalFilters?.resourceOr && (intervalFilters?.fh || intervalFilters?.fc ) ? 'flex':'none'
							}}
						>
							Logic:OR
						</Typography>
					</Box>
					<Box
						sx={{
							position:'relative'
						}}
					>
						<Tooltip title= 'Open Interval, Check Filters'>
							<Button	
								onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:intervalFilters?'#ffc3c6':'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								Interval Check
							</Button>
						</Tooltip>
						{showIntervalFilters && (
							<Box
								sx={{
									position:'absolute',
									height:300,
									width:260,
									bgcolor:'#d6d3ce',
									border:'1px solid black',
									zIndex:999,
									left:'-100px',
									top:40,
									alignItems:'center'
								}}
							>
								<Grid container spacing={1} sx={{ flexGrow: 1, px:2, py:1 }}>
									<Grid xs={12}>
										<FormControl>
											<FormLabel sx={lableStyle}>Check:</FormLabel>
											<Autocomplete
												options={checksList.map(data=> ({ label: data.check}))}
												sx={{...inputStyle}}
												onChange={(event, value) => handleChangeInterval('check', value ? value.label : '')}
												value={{ label: intervalFilters?.check || ''}}
											/>
										</FormControl>
									</Grid>

									<Grid xs={2} sx={{color:'black'}}>
										FH:
									</Grid>
									<Grid xs={10}>
										<Input
											value={intervalFilters?.fh || ''}
											onChange={(event) => handleChangeInterval('fh', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>

									<Grid xs={2} sx={{color:'black'}}>
										FC:
									</Grid>
									<Grid xs={10}>
										<Input
											value={intervalFilters?.fc || ''}
											onChange={(event) => handleChangeInterval('fc', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>

									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											DY:
										</Typography>
										<Tooltip title='Days Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
												}}
												checked={intervalFilters?.calendarKind === 'DY'}
												onChange={() => handleChangeInterval('calendarKind', 'DY')}
											/>
										</Tooltip>
									</Grid>
									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											MO:
										</Typography>
										<Tooltip title='Months Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
												}}
												checked={intervalFilters?.calendarKind === 'MO'}
												onChange={() => handleChangeInterval('calendarKind', 'MO')}
											/>
										</Tooltip>
									</Grid>
									<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
										<Typography sx={{color:'black',fontSize:16}}>
											YR:
										</Typography>
										<Tooltip title='Years Selector'>
											<Checkbox 
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'14px',
													'--Checkbox-size':'15px',
													gap:0.4,
													'& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													},   
												}}
												checked={intervalFilters?.calendarKind === 'YR'}
												onChange={() => handleChangeInterval('calendarKind', 'YR')}
											/>
										</Tooltip>
									</Grid>
											
									<Grid xs={2}></Grid>
									<Grid xs={8}>
										<Input
											value={intervalFilters?.calendarValue || ''}
											onChange={(event) => handleChangeInterval('calendarValue', event.target.value)}
											sx={{...inputStyle}}
										/>
									</Grid>
									<Grid xs={2}></Grid>

									<Grid xs={2}></Grid>
									<Grid xs={4}>
										<Radio
											color='neutral' 
											variant='outlined'
											label='And'
											checked={!intervalFilters?.resourceOr || false}
											onChange={() => handleChangeInterval('resourceOr', false)}
										/>
									</Grid>	
									<Grid xs={4}>
										<Radio
											color='neutral' 
											variant='outlined'
											label='Or'
											checked={intervalFilters?.resourceOr || false}
											onChange={() => handleChangeInterval('resourceOr', true)}
										/>
									</Grid>	
									<Grid xs={2}></Grid>

									<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
										<Button
											onClick={confirmIntervalFilters}	
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Ok
										</Button>
										<Button
											onClick={cancelIntervalFilters}	
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Cancel
										</Button>
										<Button
											onClick={resetIntervalFilters}
											sx={{
												borderRadius:0,
												height: 25, 
												minHeight: 0,
												bgcolor:'#d6d3ce',
												color:'black',
												border:'2px outset grey',
											}}
										>
											Reset
										</Button>
									</Grid>
								</Grid>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display:'flex',
							flexDirection:'column',
							mb:1,
							gap:1
						}}
					>
						<Tooltip title='Filter Tasks has No Entrance to Checks'>
							<Checkbox 
								variant="outlined"
								color="neutral"
								label='No MP'
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& span': {
										borderRadius: '0px',
										borderWidth:'1px',
										borderColor:'black',
										color:'black'
									},     
								}}
								checked={checkboxesFilters?.outOfMp || false}
								onChange={(event) => handleChangeCheckboxes('outOfMp', event.target.checked)}
							/>
						</Tooltip>
						<Tooltip title='Filter Tasks with APU Flight Data Utilization'>
							<Checkbox 
								variant="outlined"
								color="neutral"
								label='APU'
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& span': {
										borderRadius: '0px',
										borderWidth:'1px',
										borderColor:'black',
										color:'black'
									},   
								}}
								checked={checkboxesFilters?.apu || false}
								onChange={(event) => handleChangeCheckboxes('apu', event.target.checked)}
							/>
						</Tooltip>
					</Box>
				</Box>
				<Box
					onClick={() => setShowFilters(!showFilters)}
					sx={{
						border:'1px solid black',
						borderBottom:'none',
						height:'24px',
						display:'flex',
						alignItems:'center',
						justifyContent:'center',
						bgcolor:checkFilters() ? 'transparent':'#DDE7EE',
						mx:'16px',
					}}
				>
					<ArrowDropDownIcon 
						sx={{
							fontSize: '24px',
							transition: '0.2s',
							transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
						}}
					/>
				</Box>
				<Sheet
					id='tablesSheet'
					variant="outlined"
					sx={{
						position:'absolute',
						width: `calc(100% - 32px)`,
						height:showTaskTable?`calc(80% - 135px)`:`calc(100% - 150px)`,
						borderRadius: '0px',
						flexShrink: 1,
						overflow: 'auto',
						border: '1px solid black',
						bgcolor:'#848284',
						mx:'16px',
					}}
				>
					<Table
						id='table'
						borderAxis="both"
						stickyHeader
						hoverRow
						sx={{
							'--TableCell-headBackground': '#d6d3ce',
							'--Table-headerUnderlineThickness': '1px',
							'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
							'--TableCell-paddingY': '1px',
							'--TableCell-height': '29px',
							'--TableCell-paddingX': '5px',
							'--TableCell-borderColor': '#939293',
							tableLayout: 'auto', 
							width:'auto',
							borderRadius:'0px'
						}}
					>
						<thead>
							<tr>
								<th style={headerStyle}>
									<Typography
										onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
										endDecorator={<ArrowDropDownIcon />}
										sx={{
											'& svg': {
												color:sort.lable === 'id' ? '#636b74' : 'transparent',
												transition: '0.2s',
												transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
											},
											fontWeight:filters?.id ? 'bold' : 'normal'
										}}
									>
										ID
									</Typography>
								</th>
								{headers.map((row) => (
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === row.id ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.[row.id] ? 'bold' : 'normal'
											}}
										>
											{row.name}
										</Typography>
									</th>
								))}
							</tr>
							{showFilters && (
								<tr>
									<th style={{padding:0}}>
										<Input
											value={filters?.id || ''}
											onChange={(event) => handleChange('id', event.target.value)}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.ata || ''}
											onChange={(event) => handleChange('ata', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.task || ''}
											onChange={(event) => handleChange('task', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.basicTask || ''}
											onChange={(event) => handleChange('basicTask', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.jic || ''}
											onChange={(event) => handleChange('jic', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.taskTitle || ''}
											onChange={(event) => handleChange('taskTitle', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.taskDescription || ''}
											onChange={(event) => handleChange('taskDescription', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.taskType))].filter(row => row).map(taskType=> ({ label: taskType}))}
											onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
											value={{label: filters?.taskType || ''}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.taskEffectivity))].filter(row => row).map(taskEffectivity=> ({ label: taskEffectivity}))}
											onChange={(event, value) => handleChange('taskEffectivity', value ? value.label : '')}
											value={{label: filters?.taskEffectivity || ''}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fhStart || ''}
											onChange={(event) => handleChange('fhStart', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fcStart || ''}
											onChange={(event) => handleChange('fcStart', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.calendarStart))].filter(row => row).map(calendarStart=> ({ label: calendarStart}))}
											onChange={(event, value) => handleChange('calendarStart', value ? value.label : '')}
											value={{label: filters?.calendarStart || ''}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.calendarValueStart || ''}
											onChange={(event) => handleChange('calendarValueStart', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.docReferenceStart || ''}
											onChange={(event) => handleChange('docReferenceStart', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fhInterval || ''}
											onChange={(event) => handleChange('fhInterval', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fcInterval || ''}
											onChange={(event) => handleChange('fcInterval', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.calendarInterval))].filter(row => row).map(calendarInterval=> ({ label: calendarInterval}))}
											onChange={(event, value) => handleChange('calendarInterval', value ? value.label : '')}
											value={{label: filters?.calendarInterval || ''}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.calendarValueInterval || ''}
											onChange={(event) => handleChange('calendarValueInterval', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.docReferenceInterval || ''}
											onChange={(event) => handleChange('docReferenceInterval', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.completedByComponentReplacement || ''}
											onChange={(event) => handleChange('completedByComponentReplacement', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.ipcPositions || ''}
											onChange={(event) => handleChange('ipcPositions', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fhFinish || ''}
											onChange={(event) => handleChange('fhFinish', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.fcFinish || ''}
											onChange={(event) => handleChange('fcFinish', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Autocomplete
											options={[...new Set(data.map(data => data.calendarFinish))].filter(row => row).map(calendarFinish=> ({ label: calendarFinish}))}
											onChange={(event, value) => handleChange('calendarFinish', value ? value.label : '')}
											value={{label: filters?.calendarFinish || ''}}
											sx={filtersStyle}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.calendarValueFinish || ''}
											onChange={(event) => handleChange('calendarValueFinish', event.target.value)}
											sx={filtersStyle}
											type="number"
											slotProps={{
												input: {
													step: 1,
													min:0
												}
											}}
										/>
									</th>
									<th style={{padding:0}}>
										<Input
											value={filters?.docReferenceFinish || ''}
											onChange={(event) => handleChange('docReferenceFinish', event.target.value)}
											sx={filtersStyle}
										/>
									</th>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
									<th/>
								</tr>
							)}
						</thead>
						<tbody>
							{filteredData.length == 0 && (
								<th colSpan={999}>
									<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
								</th>
							)}
							{filteredData.map((row, index) => (
								<tr
									key={row.id}
									onClick={() => handleRowSelection(row)}
									style={{ 
										backgroundColor: checkRowColor(row, index),
										color:'black',
									}}
								>
									<td style={{...rowStyle, backgroundColor: props.selectedRows.indexOf(row?.id) > -1 ? '#c0c0ff' : row.apuUtilization ? '#ffc3ff' : '#c6c3c6'}}>{row.id}</td>
									<td style={rowStyle}>{row.ata}</td>
									<td style={rowStyle}>{row.task}</td>
									<td style={rowStyle}>{row.basicTask}</td>
									<td style={rowStyle}>{row.jic}</td>
									<td style={rowStyle}>{row.taskTitle}</td>
									<td style={rowStyle}>{row.taskDescription}</td>
									<td style={rowStyle}>{row.taskType}</td>
									<td style={rowStyle}>{row.taskEffectivity}</td>
									<td style={rowStyle}>{row.fhStart}</td>
									<td style={rowStyle}>{row.fcStart}</td>
									<td style={rowStyle}>{row.calendarStart}</td>
									<td style={rowStyle}>{row.calendarValueStart}</td>
									<td style={rowStyle}>{row.docReferenceStart}</td>
									<td style={rowStyle}>{row.fhInterval}</td>
									<td style={rowStyle}>{row.fcInterval}</td>
									<td style={rowStyle}>{row.calendarInterval}</td>
									<td style={rowStyle}>{row.calendarValueInterval}</td>
									<td style={rowStyle}>{row.docReferenceInterval}</td>
									<td style={rowStyle}>{row.completedByComponentReplacement}</td>
									<td style={rowStyle}>{row.ipcPositions}</td>
									<td style={rowStyle}>{row.fhFinish}</td>
									<td style={rowStyle}>{row.fcFinish}</td>
									<td style={rowStyle}>{row.calendarFinish}</td>
									<td style={rowStyle}>{row.calendarValueFinish}</td>
									<td style={rowStyle}>{row.docReferenceFinish}</td>
									<td style={rowStyle}>{row.etops}</td>
									<td style={rowStyle}>{row.etopsII}</td>
									<td style={rowStyle}>{row.di}</td>
									<td style={rowStyle}>{row.cat3}</td>
									<td style={rowStyle}>{row.cr}</td>
									<td style={rowStyle}>{row.cdccl}</td>
									<td style={rowStyle}>{row.ali}</td>
									<td style={rowStyle}>{row.ewis}</td>
									<td style={rowStyle}>{row.cpcp}</td>
									<td style={rowStyle}>{row.cmr}</td>
									<td style={rowStyle}>{row.ad}</td>
									<td style={rowStyle}>{row.instructionReference}</td>
									<td style={rowStyle}>{row.apuUtilization.toString()}</td>
									<td style={rowStyle}>{row.nonScheduled}</td>								
								</tr>
							))}
						</tbody>
					</Table>
				</Sheet>
				<Box
					sx={{
						position:'absolute',
						width: `calc(100% - 32px)`,
						height:`calc(20% - 30px)`,
						borderRadius: '0px',
						flexShrink: 1,
						border: '1px solid black',
						mx:'16px',
						bottom:'30px',
						display:showTaskTable? 'flex':'none'
					}}
				>
					<Box
						sx={{
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							top:-12,
							left:5,
							bgcolor:'#d6d3ce'
						}}
					>
						Task - Checks Model: {props.selectedRows?.length !== 0 && 'ID =' + props.selectedRows[props.selectedRows.length - 1]}
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`90%`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							borderBottom:'none',
							bgcolor:'#848284',
							mx:'16px',
							bottom:0,
							zIndex:99
						}}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('check', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'check' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},	
											}}
										>
											Check
										</Typography>
									</th>
									{tasksHeaders.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								
								{tasksData.length === 0 && props.selectedRows.length !== 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center', fontSize:'14px'}}>No Checks Where Found</td>
									</th>
								)}
								{props.selectedRows.length === 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center', fontSize:'14px'}}>NO TASK IS SELECTED! SELECT</td>
									</th>
								)}
								{tasksData.map((row, index) => (
									<tr
										key={row.check}
										//onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: props.selectedRows.indexOf(row?.id) > -1 ? '#c0c0ff' : row.apuUtilization ? '#ffc3ff' : '#c6c3c6'}}>{row.check}</td>
										<td style={rowStyle}>{row.checkDescription}</td>
										<td style={rowStyle}>{row.checkType}</td>
										<td style={rowStyle}>{row.fhStart}</td>
										<td style={rowStyle}>{row.fcStart}</td>
										<td style={rowStyle}>{row.calendarStart}</td>
										<td style={rowStyle}>{row.calendarValueStart}</td>
										<td style={rowStyle}>{row.fhInterval}</td>
										<td style={rowStyle}>{row.fcInterval}</td>
										<td style={rowStyle}>{row.calendarInterval}</td>
										<td style={rowStyle}>{row.calendarValueInterval}</td>
										<td style={rowStyle}>{row.fhFinish}</td>
										<td style={rowStyle}>{row.fcFinish}</td>
										<td style={rowStyle}>{row.calendarFinish}</td>
										<td style={rowStyle}>{row.calendarValueFinish}</td>								
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				<Box
					sx={{
						display: 'flex',
						position:'absolute',
						width: `calc(100% - 32px)`,
						height:`20px`,
						bottom:5,
						mx:'16px',
						alignItems:'center',
						justifyContent:'space-between',
					}}
				>
					{filteredData?.length !== 0 ? (
						<Typography
							sx={{
								fontSize:'12px',
							}}
						>
							Found {filteredData?.length} Records
						</Typography>
					):(
						<Typography
							sx={{
								fontSize:'12px',
							}}
						>
							No Records were Found!
						</Typography>
					)}
					<Checkbox 
						variant="outlined"
						color="neutral"
						label='Show Task-Check Model'
						sx={{
							fontSize:'14px',
							'--Checkbox-size':'15px',
							gap:0.4,
							'& span': {
								borderRadius: '0px',
								borderWidth:'1px',
								borderColor:'black',
								color:'black'
							},     
						}}
						checked={showTaskTable}
						onChange={(event) => setShowTaskTable(event.target.checked)}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
