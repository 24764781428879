import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import AmpModelTree from './TreeViews/AmpModelTree.tsx';
import Spiner from '../../../Components/Spiner.tsx'

export default function Model(props) {
	const [checkId, setCheckId] = React.useState(0);
	const [recordsCount, setReacordsCount] = React.useState(0);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	const [showSpinner, setShowSpinner] = React.useState(0)

	//Изменение ширины AddEditForm
	const [isDragging, setIsDragging] = React.useState(false);
	const [defWidth, setDefWidth] = React.useState(600)
  	const [addEditFormWidth, setAddEditFormWidth] = React.useState(defWidth);
	const [startPosition, setStartPosition] = React.useState({ x: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ x: e.clientX});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaX = e.clientX - startPosition.x;
		setAddEditFormWidth(defWidth-deltaX)
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefWidth(addEditFormWidth)
	};
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			{showSpinner !== 0 && (<Spiner/>)}
			<Box
				id='main'
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:`auto 10px ${addEditFormWidth}px`,
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
						minWidth:'100px'
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							whiteSpace: 'nowrap', 
							overflow: 'hidden',    
							textOverflow: 'ellipsis',
							width:`calc(100% - 9px)`,
							left:16
						}}
					>
						Model's Maintenance Checks:
					</Typography>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 52px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'white',
							mx:'16px',
							mt:'22px',
						}}
					>
						<AmpModelTree 
							ampId={props.selectedAmp?.id} 
							acFamily={props.selectedACFamily}
							setCheckId={setCheckId}		
							setShowSpinner={setShowSpinner}
							selectedOperator={props.selectedOperator}
							setReacordsCount={setReacordsCount}
						/>
					</Sheet>
					<Box
						sx={{
							display: 'flex',
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`20px`,
							bottom:5,
							mx:'16px',
							alignItems:'center',
							justifyContent:'space-between',
						}}
					>
						{recordsCount !== 0 ? (
							<Typography
								sx={{
									fontSize:'12px',
								}}
							>
								Found {recordsCount} Checks
							</Typography>
						):(
							<Typography
								sx={{
									fontSize:'12px',
								}}
							>
								No Checks were Found!
							</Typography>
						)}
					</Box>
				</Box>
				<Box
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						cursor:'ew-resize',
						position:'relative',
					}}
				>
					<Divider 
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						orientation="vertical" 
						sx={{
							cursor:'ew-resize',
							position:'absolute',
							height:'100%'
						}}
					/>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedRow={checkId} 
						selectedAmpId={props.selectedAmp?.id}
						setResponseStatus={setResponseStatus}
						
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
