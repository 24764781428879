import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import RadioGroup from '@mui/joy/RadioGroup';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import FlagIcon from '@mui/icons-material/Flag';

import MoveTaskWnd from './MoveTaskWnd.tsx';
import { PowerInputSharp } from '@mui/icons-material';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 26, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'14px', alignSelf:'center', lineHeight:1 };
	const formControlStyle = { my: 0.2 };
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', lineHeight:1};
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};

	const [editedData, setEditedData] = React.useState({});
	const [taskIdList, setTaskIdList] = React.useState([]);
	const [taskTypeList, setTaskTypeList] = React.useState([]);
	const [taskEffList, setTaskEffList] = React.useState([]);
	const [mainZoneList, setMainZoneList] = React.useState([]);
	const [mrbCodeList, setMrbCodeList] = React.useState([]);
	const [isAtaValid, setIsAtaValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);
	const [greenFlags, setGreenFlags] = React.useState({interval:false, start:false, finish:false, tolerance:false, instractions:false, post:false, lump:false, docRef:false, specialInsp:false, panels:false, materials:false, tools:false, jic:false, control:false})

	const [showTabsEditore, setShowTabsEditore] = React.useState(false);
	const [showMoveTaskWnd, setShowMoveTaskWnd] = React.useState(false)

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		isGreenFlag();
		setIsAtaValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	const fetchTaskIdList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs?$apply=filter(AmpId eq ${props.selectedAmpId})/groupby((id, Task))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setTaskIdList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const fetchTaskTypeList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskTypes()`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setTaskTypeList(result.value);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const fetchTaskEffList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities?$apply=filter(AmpId eq ${props.selectedAmpId})/groupby((AcConfigurationDescription))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setTaskEffList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const fetchMainZoneList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs?&$apply=filter(AmpId eq ${props.selectedAmpId} and MainZone ne null and MainZone ne '')/groupby((MainZone))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setMainZoneList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const fetchMrbCodeList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes?$apply=filter(AmpId eq ${props.selectedAmpId})/groupby((MrbCode))`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setMrbCodeList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	

	React.useEffect(() => {
		if(props?.selectedAmpId) {
			fetchTaskIdList();
			fetchTaskTypeList();
			fetchTaskEffList();
			fetchMainZoneList();
			fetchMrbCodeList();
		}
	}, [props?.selectedAmpId]);

	//Запрос проверки валидности ATA
	const checkValidATA = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/ValidAta()?ata='${editedData.ataCode}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsAtaValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = async () =>  {
		if (await checkValidATA()) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.ataCode === props.selectedRow.ataCode || await checkValidATA()){
			setIsAtaValid(true);
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	const isGreenFlag = () => {
		if(props.selectedRow?.id === 0) {
			setGreenFlags (null); 
			return
		}
		const flags = {...greenFlags};
		//console.log(props.selectedRow)
		flags.interval = !!(props.selectedRow?.fhInterval || props.selectedRow?.fcInterval || props.selectedRow?.calendarValueInterval || props.selectedRow?.docReferenceInterval);
		flags.start = !!(props.selectedRow?.fhStart || props.selectedRow?.fcStart || props.selectedRow?.calendarValueStart || props.selectedRow?.docReferenceStart);
		flags.finish = !!(props.selectedRow?.fhFinish || props.selectedRow?.fcFinish || props.selectedRow?.calendarValueFinish || props.selectedRow?.docReferenceFinish);
		flags.instructions = !!(props.selectedRow?.etops || props.selectedRow?.etopsII || props.selectedRow?.cat3 || props.selectedRow?.fls56fc || props.selectedRow?.di || props.selectedRow?.cr || props.selectedRow?.cdccl || props.selectedRow?.fls76fc || props.selectedRow?.ali || props.selectedRow?.ewis || props.selectedRow?.cmr || props.selectedRow?.cpcp || props.selectedRow?.ad || props.selectedRow?.instructionReference || props.selectedRow?.conditional || props.selectedRow?.nonScheduled);
		setGreenFlags(flags)
	}

	const IntervalEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Interval:
					</Typography>
					<Typography
						sx={{
							ml:3,
							bgcolor:'#d6d3ce',
							color:'red'
						}}
					>
						*
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={7}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarInterval ==='DY'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarInterval ==='MO'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarInterval ==='YR'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhInterval ?? ''}
									onChange={(event) => handleChange('fhInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcInterval ?? ''}
									onChange={(event) => handleChange('fcInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueInterval ?? ''}
									onChange={(event) => handleChange('calendarValueInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={4}>
							<FormControl sx={{...formControlStyle, ml:0.4}}>
								<Tooltip title='APU Flight Data Utilization'>
									<Checkbox 
										label=':APU Data'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.apuUtilization}
										onChange={(event) => handleChange('apuUtilization', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={8}>
							<FormControl>
								<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.docReferenceInterval ?? ''}
									onChange={(event) => handleChange('docReferenceInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={{mt:2}}>
								<Tooltip title='Whichever Comes Last (Intervals Value)'>
									<Checkbox 
										label=':Whichever Comes Last'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.whicheverLast === 'Y'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl>
								<Tooltip title='Completed By Component Replacement'>
									<Checkbox 
										label=':Completed By Component RepIm.'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.completedByComponentReplacement === 'Y' ? true : false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Reference:</FormLabel>
								<Textarea 
									maxRows={1}
									//defaultValue={props.selectedRow.description}
									sx={{...inputStyle,color:'#000078', height:60}}
									//value={editedData?.remarks || ''}
									//onChange={(event) => handleChange('remarks', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const StartThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Start Threshold:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={7}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarStart === 'DY'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarStart === 'MO'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarStart === 'YR'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhStart?? ''}
									onChange={(event) => handleChange('fhStart', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcStart?? ''}
									onChange={(event) => handleChange('fcStart', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueStart ?? ''}
									onChange={(event) => handleChange('calendarValueStart', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.docReferenceStart ?? ''}
									onChange={(event) => handleChange('docReferenceStart', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						
					</Grid>
				</Grid>
			</Box>
		)
	}

	const FinishThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Finish Threshold:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={7}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarFinish === 'DY' ? true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarFinish === 'MO' ? true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.calendarFinish === 'YR' ? true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhFinish ?? ''}
									onChange={(event) => handleChange('fhFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcFinish ?? ''}
									onChange={(event) => handleChange('fcFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueFinish ?? ''}
									onChange={(event) => handleChange('calendarValueFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.docReferenceFinish ?? ''}
									onChange={(event) => handleChange('docReferenceFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						
					</Grid>
				</Grid>
			</Box>
		)
	}

	const ToleranceEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tolerance:
					</Typography>
					<Typography
						sx={{
							ml:3,
							bgcolor:'#d6d3ce',
							color:'red',
						}}
					>
						*
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Early Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.earlyReschedMethod === 'Completion'}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.earlyReschedMethod === 'When due'}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Late Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.lateReschedMethod === 'Completion'}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.lateReschedMethod === 'When due'}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

					</Grid>
				</Grid>
			</Box>
		)
	}

	const InstructionsEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						INSTRUCTIONS:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140, pt:1 }}>
					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS Y or N'>
							<Checkbox 
								label=':ETOPS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.etops === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Double Inspection Y or N'>
							<Checkbox 
								label=':DI (RII)'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.di === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Airworthiness Limitation Instruction Y or N'>
							<Checkbox 
								label=':ALI'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.ali === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='AD Instruction Y or N'>
							<Checkbox 
								label=':AD'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.ad === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>

					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS II Y or N'>
							<Checkbox 
								label=':ETOPS II'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.etopsII === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Task Y or N'>
							<Checkbox 
								label=':CR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.cr === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='EWIS Y or N'>
							<Checkbox 
								label=':EWIS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.ewis === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={4} sx={{position:'relative'}}>	
						<Typography sx={{...labelStyle,top:-18,left:70,position:'absolute', color:'black'}}>Reference:</Typography>
						<Input  
							sx={inputStyle} 
							value={editedData?.instructionReference ?? ''}
							onChange={(event) => handleChange('instructionReference', event.target.value)}
						/>	
					</Grid>
					<Grid xs={1}></Grid>

					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='Category III approach capability affected Y or N'>
							<Checkbox 
								label=':CAT 3'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.cat3 === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Design Configuration Control Limitation Y or N'>
							<Checkbox 
								label=':CDCCL'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.cdccl === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Certificated Maintenance Requirements Y or N'>
							<Checkbox 
								label=':CMR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.cmr === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='Conditional Task - Will be performed ON Condition has no Interval Value'>
							<Checkbox 
								label=':CONDITIONAL TASK'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									fontWeight:'bold',
									'--Checkbox-size':'15px',
									color:'#3c0203',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.conditional === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>

					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='Flight Length Sensitive - 56.000 FC Maximum'>
							<Checkbox 
								label=':FLS 56FC'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									fontWeight:'bold',
									'--Checkbox-size':'15px',
									color:'#3c0203',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.fls56fc === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Flight Length Sensitive - 75.000 FC Maximum'>
							<Checkbox 
								label=':FLS 75FC'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									fontWeight:'bold',
									'--Checkbox-size':'15px',
									color:'#3c0203',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.fls75fc === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='CPCP'>
							<Checkbox 
								label=':CPCP'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.cpcp === 'Y' ? true:false}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='Task Non-Scheduled Will Be Not Visible in Initializing'>
							<Checkbox 
								label=':NON_SCHEDULED'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									fontWeight:'bold',
									'--Checkbox-size':'15px',
									color:'#77040c',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedData.nonScheduled}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const PostThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						POST THRESHOLD INTERVALS FOR STRUCTURES AND ZONAL TASKS:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140, pt:1 }}>
					<Grid container spacing={0} xs={2}>
						<Grid xs={12}/>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>Switching FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>Switching FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={0} xs={3}>
						<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
							Switching Calendar:
						</Grid>

						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={12}>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}> 
							POST Switching Interval:
						</Grid>

						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}/>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={6}>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={0} xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Grid xs={12}/>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Save
							</Button>
						</Grid>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const LUMPEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Interval - Low Utilization MP:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={7}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={4}></Grid>
						<Grid xs={8}>
							<FormControl>
								<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={{mt:2}}>
								<Tooltip title='Whichever Comes Last (Intervals Value)'>
									<Checkbox 
										label=':Whichever Comes Last'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl>
								<Tooltip title='Completed By Component Replacement'>
									<Checkbox 
										label=':Completed By Component RepIm.'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Reference:</FormLabel>
								<Textarea 
									maxRows={1}
									defaultValue={props.selectedRow.description}
									sx={{...inputStyle,color:'#000078', height:60}}
									value={editedData?.remarks || ''}
									onChange={(event) => handleChange('remarks', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:0,
							m:0.2
						}}
					>
						Save
					</Button>
				</Grid>
			</Box>
		)
	}

	const DocRef = () => {
		const [docRefData, setDocRefData] = React.useState([])
		const [selectedDocRef, setSelectedDocRef] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedDocRef.id){
				setSelectedDocRef({id:0});
			} else {
				setSelectedDocRef(row);
			}  
		};

		React.useEffect(() => {
			const fetchDocRefData = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DocReferences(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setDocRefData(result.value);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchDocRefData()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Document Reference:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{docRefData?.length === 0 ? (
									<Box>No Referenced Documents Were Found !</Box>
								):(
									<tbody>
										{docRefData.map((row) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: selectedDocRef === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.docType}</td>
												<td style={rowStyle}>{row.reference}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const DocRefEditor = () => {
		const [filters, setFilters] = React.useState({reference:''})
		const [editedDocRefData, setEditedDocRefData] = React.useState({docType:'', reference:''})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [docTypesList, setDocTypesList] = React.useState([])
		const [isDocExist, setIsDocExist] = React.useState(true)

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AllDocReferences(ampId=${props.selectedAmpId},referenceFilter='${filters.reference}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		//Запрос списка DocTypes
		React.useEffect(() => {
			const fetchDocTypesList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AllDocTypes(ampId=${props.selectedAmpId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setDocTypesList(result.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchDocTypesList ();
		}, []);

		const fetchCheckDocReference = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/CheckDocReference(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},doctype='${editedDocRefData.docType}',reference='${editedDocRefData.reference}')`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setIsDocExist(result.value)
				return result.value
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};

		const handleAdd = async () =>  {
			if (!await fetchCheckDocReference()) {
				fetch(
					`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AddDocReference`, 
					{
						method: 'POST',
						headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							docreference:{
								ampId:props.selectedAmpId,
								taskid:props.selectedRow.id,
								reference:editedDocRefData?.reference,
								doctype:editedDocRefData?.docType
							}
						})
					}
				)
				.then((response) => {
					if (!response.ok){
						props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
					} else {
						props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
					}
					//props.reloadTable();
					return response.json();
				})
				.catch(error => console.error('Ошибка создания группы:', error));
			} 
		}

		const handleDelete = async () => {
			if (selectedRow) {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DeleteDocReference(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},doctype='${selectedRow.docType}',reference='${selectedRow.reference}')`,
					{
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						}
					});
				if (response.ok) {
					props.setResponseStatus({ ...props.responseStatus, successfulStatus: 'Delete' });
					//props.reloadTable();
					//setSelectedRow({});
				} else {
					props.setResponseStatus({ ...props.responseStatus, errorStatus: response.status });
				}
			}
		};
	
		// Обработчик нажатия клавиш
		React.useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.key === 'Delete') {
					handleDelete();
				}
			};
	
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [selectedRow]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Document Reference Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
						width:'100%'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'30% 50% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='Document Reference Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.reference?? ''}
								onChange={(event) => setFilters({ ...filters, reference:event.target.value})}
							/>
						</Tooltip>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "10px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Any Reference Were Found !</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedRow(row)}
												onDoubleClick={()=>setEditedDocRefData(row)}
												style={{ 
													backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
													color:'black',
													cursor:'default'
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.docType}</td>
												<td style={rowStyle}>{row.reference}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'16% 17% 44% 20%',
							gridTemplateColumns:'33% 33% 33%',
							position:'relative',
						}}
					>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:3,gridRow:2}}>
							<FormLabel sx={labelStyle}>Doc. Type:</FormLabel>
							<Autocomplete
								disableClearable
								options={docTypesList}
								value={{ label: editedDocRefData?.docType ?? ''}}
								onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
								sx={{...inputStyle, gridColumnStart:3, gridColumnEnd:5}}
							/>
						</FormControl>
						
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:3}}>
							<FormLabel sx={labelStyle}>Document Reference:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'80%',
								}}
								value={editedDocRefData?.reference ?? ''}
								onChange={(event) => setEditedDocRefData({ ...editedDocRefData, reference:event.target.value})}
							/>
						</FormControl>
						<Box sx={{gridColumn:2,gridRow:4}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleAdd}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
						</Box>
						<Box sx={{gridColumn:3,gridRow:4}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowTabsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const SpecialInsp = () => {
		const [specialInsp, setSpecialInsp] = React.useState([])
		const [selectedSpecialInsp, setSelectedSpecialInsp] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedSpecialInsp.id){
				setSelectedSpecialInsp({id:0});
			} else {
				setSelectedSpecialInsp(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchSpecialInsp = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/SpecialInspections(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setSpecialInsp(result.value);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchSpecialInsp()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Special Inspection:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{specialInsp?.length === 0 ? (
									<Box>No Special Instraction Were Found !</Box>
								):(
									<tbody>
										{specialInsp.map((row) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: selectedSpecialInsp === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.docType}</td>
												<td style={rowStyle}>{row.reference}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const SpecialInspEditor = () => {
		const [filters, setFilters] = React.useState({details:''})
		const [editedSpecialInspData, setEditedSpecialInspData] = React.useState({inspectionType:'', inspectionDetails:''})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [isDocExist, setIsDocExist] = React.useState(true)

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AllSpecialInspections(ampId=${props.selectedAmpId},detailsFilter='${filters.details}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		const fetchCheckSpecialInspection = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/CheckInspection(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},inspectionType='${editedSpecialInspData.inspectionType}')`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setIsDocExist(result.value)
				return result.value
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};

		const handleAdd = async () =>  {
			if (!await fetchCheckSpecialInspection()) {
				fetch(
					`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AddSpecialInspection`, 
					{
						method: 'POST',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							inspection:{
								ampId:props.selectedAmpId,
								taskid:props.selectedRow.id,
								inspectionType:editedSpecialInspData?.inspectionType,
								inspectionDetails:editedSpecialInspData?.inspectionDetails
							}
						})
					}
				)
				.then((response) => {
					if (!response.ok){
						props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
					} else {
						props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
					}
					//props.reloadTable();
					return response.json();
				})
				.catch(error => console.error('Ошибка создания группы:', error));
			} 
		}

		const handleDelete = async () => {
			if (selectedRow) {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DeleteSpecialInspection(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},inspectionType='${selectedRow.inspectionType}')`,
					{
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						}
					});
				if (response.ok) {
					props.setResponseStatus({ ...props.responseStatus, successfulStatus: 'Delete' });
					//props.reloadTable();
					//setSelectedRow({});
				} else {
					props.setResponseStatus({ ...props.responseStatus, errorStatus: response.status });
				}
			}
		};
	
		// Обработчик нажатия клавиш
		React.useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.key === 'Delete') {
					handleDelete();
				}
			};
	
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [selectedRow]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Special Inspection Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
						width:'100%'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'50% 30% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='Special Inspection Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.details?? ''}
								onChange={(event) => setFilters({ ...filters, details:event.target.value})}
							/>
						</Tooltip>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "10px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Special Instraction Were Found !</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.inspectionType}
												onClick={() => setSelectedRow(row)}
												onDoubleClick={()=>setEditedSpecialInspData(row)}
												style={{ 
													backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
													color:'black',
													cursor:'default'
												}}
											>
												<td style={rowStyle}>{row.inspectionType}</td>
												<td style={rowStyle}>{row.inspectionDetails}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'16% 17% 44% 20%',
							gridTemplateColumns:'33% 33% 33%',
							position:'relative',
						}}
					>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:2}}>
							<FormLabel sx={labelStyle}>Inspection Type:</FormLabel>
							<Input  
								sx={{...inputStyle, gridColumnStart:3, gridColumnEnd:5}}
								value={editedSpecialInspData.inspectionType?? ''}
								onChange={(event) => setEditedSpecialInspData({ ...editedSpecialInspData, inspectionType:event.target.value})}
							/>
						</FormControl>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:3}}>
							<FormLabel sx={labelStyle}>Inspection Details:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'80%',
								}}
								value={editedSpecialInspData?.inspectionDetails?? ''}
								onChange={(event) => setEditedSpecialInspData({ ...editedSpecialInspData, inspectionDetails:event.target.value})}
							/>
						</FormControl>
						<Box sx={{gridColumn:2,gridRow:4}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleAdd}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
						</Box>
						<Box sx={{gridColumn:3,gridRow:4}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowTabsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const Panels = () => {
		const [panels, setPanels] = React.useState([])
		const [selectedPanels, setSelectedPanels] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedPanels.id){
				setSelectedPanels({id:0});
			} else {
				setSelectedPanels(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchPanels = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/Panels(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setPanels(result);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchPanels()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Panels:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{panels?.length === 0 ? (
									<Box>No Panels Were Found !</Box>
								):(
									<tbody>
										{panels.map((row) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: selectedPanels === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.panelCode}</td>
												<td style={rowStyle}>{row.panelName}</td>
												<td style={rowStyle}>{row.panelAccess}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const PanelsEditor = () => {
		const [filters, setFilters] = React.useState({panelCode:'', acFamily:''})
		const [editedPanelsData, setEditedPanelsData] = React.useState({pn:'', description:'', qty:1, materialType:'', unit:'', alt:false})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [accessList, setAccessList] = React.useState([])
		const [acFamilysList, setAcFamilysList] = React.useState([])
		const [isDocExist, setIsDocExist] = React.useState(true)

		React.useEffect(() => {setEditedPanelsData(selectedRow)}, [selectedRow])

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/Panels?$filter=contains(panelCode, '${filters.panelCode}') and contains(acFamily, '${props.selectedACFamily}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result?.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		//Запрос списка access
		React.useEffect(() => {
			const fetchAccessList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PanelAccesses?$select=access`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setAccessList(result.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchAccessList ();
		}, []);

		//Запрос списка AC Familys
		React.useEffect(() => {
			const fetchAcFamilysList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AuxAcFamilies()`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setAcFamilysList(result.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchAcFamilysList ();
		}, []);

		const fetchCheckPanels = async (panelId) => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/CheckPanel(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},panelId=${panelId})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setIsDocExist(result.value)
				return result.value
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};

		const handleAdd = async (panelId) =>  {
			if (!await fetchCheckPanels(panelId)) {
				fetch(
					`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/AddPanel`, 
					{
						method: 'POST',
						headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							panel:{
								ampid:props.selectedAmpId,
								taskid:props.selectedRow.id,
								panelid:panelId,
							}
						})
					}
				)
				.then((response) => {
					if (!response.ok){
						props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
					} else {
						props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
					}
					//props.reloadTable();
					return response.json();
				})
				.catch(error => console.error('Ошибка создания группы:', error));
			} 
		}

		const fetchCheckPanelsExist = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/Panels?$filter=panelCode eq '${editedPanelsData.panelCode}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setIsDocExist(result.value)
				if (result.value.length === 0){
					return false
				}else{
					return true
				}
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};

		const handleNew = async () =>  {
			if (!await fetchCheckPanelsExist()) {
				fetch(
					`${props.URL}${process.env.REACT_APP_API_PARTM}/Panels`, 
					{
						method: 'POST',
						headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							panelCode: editedPanelsData?.panelCode,
							name: editedPanelsData?.name,
							access: editedPanelsData?.access,
							acFamily: editedPanelsData?.acFamily
						})
					}
				)
				.then((response) => {
					if (!response.ok){
						props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
					} else {
						props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
					}
					//props.reloadTable();
					return response.json();
				})
				.catch(error => console.error('Ошибка создания группы:', error));
			} 
		}

		const handleUpdate = async () =>  {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/Panels/${selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						panelCode: editedPanelsData?.panelCode,
						name: editedPanelsData?.name,
						access: editedPanelsData?.access,
						acFamily: editedPanelsData?.acFamily
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}

		const handleDelete = async () => {
			if (selectedRow) {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DeletePanel(ampId=${props.selectedAmpId},taskPanelId=${selectedRow.id})`,
					{
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						}
					});
				if (response.ok) {
					props.setResponseStatus({ ...props.responseStatus, successfulStatus: 'Delete' });
					//props.reloadTable();
					//setSelectedRow({});
				} else {
					props.setResponseStatus({ ...props.responseStatus, errorStatus: response.status });
				}
			}
		};
	
		// Обработчик нажатия клавиш
		React.useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.key === 'Delete') {
					handleDelete();
				}
			};
	
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [selectedRow]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Access Panel Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
						width:'100%'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'50% 30% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='Panel Name Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.panelCode?? ''}
								onChange={(event) => setFilters({ ...filters, panelCode:event.target.value})}
							/>
						</Tooltip>
						<Input  
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={props.selectedACFamily}
						/>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "10px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Special Instraction Were Found !</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedRow(row)}
												onDoubleClick={()=>handleAdd(row.id)}
												style={{ 
													backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
													color:'black',
													cursor:'default'
												}}
											>
												<td style={rowStyle}>{row.panelCode}</td>
												<td style={rowStyle}>{row.name}</td>
												<td style={rowStyle}>{row.access}</td>
												<td style={rowStyle}>{row.acFamily}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'16% 44% 17% 20%',
							gridTemplateColumns:'25% 25% 25% 25%',
							position:'relative',
						}}
					>
						<FormControl  required sx={{gridColumnStart:1, gridColumnEnd:5,gridRow:1}}>
							<FormLabel sx={labelStyle}>Panel Number:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedPanelsData?.panelCode ?? ''}
								onChange={(event) => setEditedPanelsData({...editedPanelsData, panelCode:event.target.value})}
							/>
						</FormControl>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:5,gridRow:2}}>
							<FormLabel sx={labelStyle}>Panel Name:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'80%',
								}}
								value={editedPanelsData?.name ?? ''}
								onChange={(event) => setEditedPanelsData({...editedPanelsData, name:event.target.value})}
							/>
						</FormControl>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:3,gridRow:3}}>
							<FormLabel sx={labelStyle}>Access:</FormLabel>
							<Autocomplete
								disableClearable
								options={accessList?.map(data=> ({ label: data.access }))}
								value={{ label: editedPanelsData?.access?? ''}}
								onChange={(event, value) => setEditedPanelsData({...editedPanelsData, access:value?.label ?? ''})}
								sx={{...inputStyle, gridColumnStart:3, gridColumnEnd:5}}
							/>
						</FormControl>
						<FormControl required sx={{gridColumnStart:3, gridColumnEnd:5,gridRow:3}}>
							<FormLabel sx={labelStyle}>AC Family:</FormLabel>
							<Autocomplete
								disableClearable
								options={acFamilysList?.map(data => ({label: data}))}
								value={{ label: editedPanelsData?.acFamily?? ''}}
								onChange={(event, value) => setEditedPanelsData({...editedPanelsData, acFamily:value?.label ?? ''})}
								sx={{...inputStyle, gridColumnStart:3, gridColumnEnd:5}}
							/>
						</FormControl>
						<Box sx={{gridColumnStart:1, gridColumnEnd:5, gridRow:4, display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleNew}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								New
							</Button>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleUpdate}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Update
							</Button>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowTabsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:0.5
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const Materials = () => {
		const [materials, setMaterials] = React.useState([])
		const [selectedMaterials, setSelectedMaterials] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedMaterials.id){
				setSelectedMaterials({id:0});
			} else {
				setSelectedMaterials(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchMaterials = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/Materials(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setMaterials(result);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchMaterials()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Materials:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{materials?.length === 0 ? (
									<Box>No Materials Were Found !</Box>
								):(
									<tbody>
										{materials.map((row) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: selectedMaterials === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.pn}</td>
												<td style={rowStyle}>{row.description}</td>
												<td style={rowStyle}>{row.qty}</td>
												<td style={rowStyle}>{row.storeUnit}</td>
												<td style={rowStyle}>{row.materialType}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:75,
							top:-7
						}}
					>
						Update
					</Button>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const MaterialsEditor = () => {
		const [filters, setFilters] = React.useState({pn:'', description:''})
		const [editedMaterialsData, setEditedMaterialsData] = React.useState({pn:'', description:'', qty:1, materialType:'', unit:''})
		const [isAlternative, setIsAlternative] = React.useState(false)
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [inspectionTypesList, setInspectionTypesList] = React.useState([])
		const [isDocExist, setIsDocExist] = React.useState(true)

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogAcTypes/GetWithAcType(partNumber='${filters?.pn ?? '%'}',description='${filters?.description ?? '%'}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		//Запрос списка Units
		React.useEffect(() => {
			const fetchUnitList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/StoreUnits`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setInspectionTypesList(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchUnitList ();
		}, []);

		const fetchCheckMaterial = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/CheckMaterial(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},pn=${editedMaterialsData.pn})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setIsDocExist(result.value)
				return result.value
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};

		const handleAdd = async () =>  {
			if (!await fetchCheckMaterial()) {
				fetch(
					`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/SaveMaterial`, 
					{
						method: 'POST',
						headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							material:{
								pn:editedMaterialsData?.pn,
								qty:editedMaterialsData?.qty,
								alternative:false,
								alternativeid:null,
								ampId:props.selectedAmpId,
								taskid:props.selectedRow.id,
							}
						})
					}
				)
				.then((response) => {
					if (!response.ok){
						props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
					} else {
						props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
					}
					//props.reloadTable();
					return response.json();
				})
				.catch(error => console.error('Ошибка создания группы:', error));
			} 
		}

		const handleDelete = async () => {
			if (selectedRow) {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DeleteMaterial(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},materialId='${selectedRow.id}')`,
					{
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						}
					});
				if (response.ok) {
					props.setResponseStatus({ ...props.responseStatus, successfulStatus: 'Delete' });
					//props.reloadTable();
					//setSelectedRow({});
				} else {
					props.setResponseStatus({ ...props.responseStatus, errorStatus: response.status });
				}
			}
		};
	
		// Обработчик нажатия клавиш
		React.useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.key === 'Delete') {
					handleDelete();
				}
			};
	
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [selectedRow]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Materials Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
						width:'100%'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'50% 30% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='Part Number Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.details?? ''}
								onChange={(event) => setFilters({ ...filters, details:event.target.value})}
							/>
						</Tooltip>
						<Tooltip 
							title='Description Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.details?? ''}
								onChange={(event) => setFilters({ ...filters, details:event.target.value})}
							/>
						</Tooltip>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "10px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Materials Were Found !</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.pn}
												onClick={() => setSelectedRow(row)}
												onDoubleClick={()=>setEditedMaterialsData(row)}
												style={{ 
													backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
													color:'black',
													cursor:'default'
												}}
											>
												<td style={rowStyle}>{row.pn}</td>
												<td style={rowStyle}>{row.description}</td>
												<td style={rowStyle}>{row.ata}</td>
												<td style={rowStyle}>{row.acType}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'16% 23% 16% 2% 23% 20%',
							gridTemplateColumns:'33% 33% 33%',
							position:'relative',
						}}
					>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:5,gridRow:1}}>
							<FormLabel sx={labelStyle}>Part Number:</FormLabel>
							<Input  
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedMaterialsData?.pn ?? ''}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:2}}>
							<FormLabel sx={labelStyle}>Description:</FormLabel>
							<Textarea 
								disabled
								maxRows={1}
								sx={{
									...inputStyle,
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
									height:'80%',
								}}
								value={editedMaterialsData?.description ?? ''}
							/>
						</FormControl>
						<FormControl sx={{gridColumn:1, gridRow:3}}>
							<FormLabel sx={labelStyle}>Qty:</FormLabel>
							<Input  
								type="number"
								slotProps={{
									input: {
										step: 1,
										min:0
									}
								}}
								sx={{
									...inputStyle, 
								}} 
								value={editedMaterialsData?.qty ?? ''}
								onChange={(event) => setEditedMaterialsData({...editedMaterialsData, qty:event.target.value})}
							/>
						</FormControl>
						<FormControl sx={{gridColumn:2, gridRow:3}}>
							<FormLabel sx={labelStyle}>Type:</FormLabel>
							<Input  
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedMaterialsData?.acType ?? ''}
							/>
						</FormControl>
						<FormControl required sx={{gridColumn:3, gridRow:3}}>
							<FormLabel sx={labelStyle}>Unit:</FormLabel>
							<Autocomplete
								disableClearable
								options={inspectionTypesList}
								value={{ label: editedMaterialsData?.unit ?? ''}}
								onChange={(event, value) => setEditedMaterialsData({...editedMaterialsData, unit:value?.label ?? ''})}
								sx={{...inputStyle}}
							/>
						</FormControl>
						<FormControl sx={{gridColumn:1, gridRow:4, ml:0.5}}>
							<Tooltip title='Alternative Y or N'>
								<Checkbox 
									label=':ALT'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										fontWeight:'bold',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
									}}
									checked={isAlternative}
									onChange={(event) => setIsAlternative(event.target.checked)}
								/>
							</Tooltip>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:5}}>
							<FormLabel sx={labelStyle}>Remarks:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'70%',
								}}
								value={editedMaterialsData?.remarks ?? ''}
								onChange={(event) => setEditedMaterialsData({...editedMaterialsData, remarks:event.target.value})}
							/>
						</FormControl>
						<Box sx={{gridColumn:2,gridRow:6}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleAdd}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
						</Box>
						<Box sx={{gridColumn:3,gridRow:6}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowTabsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const Tools = () => {
		const [tools, setTools] = React.useState([])
		const [selectedTools, setSelectedTools] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedTools.id){
				setSelectedTools({id:0});
			} else {
				setSelectedTools(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchTools = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/Tools(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTools(result.value);
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchTools()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tools:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tools?.length === 0 ? (
									<Box>No Tools Were Found !</Box>
								):(
									<tbody>
										{tools.map((row) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: selectedTools === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.oemPn}</td>
												<td style={rowStyle}>{row.supplierPn}</td>
												<td style={rowStyle}>{row.optionalPn}</td>
												<td style={rowStyle}>{row.description}</td>
												<td style={rowStyle}>{row.remarks}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const ToolsEditor = () => {
		const [filters, setFilters] = React.useState({pn:'', description:''})
		const [editedToolsData, setEditedToolsData] = React.useState({id:0, oemPn:'', supplierPn:'', optionalPn:'', description:'', remarks:''})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [isDocExist, setIsDocExist] = React.useState(true)

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTools/View(oemPn='${filters?.pn}',description='${filters?.description}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		const handleAdd = async () =>  {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/SaveMrTool`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						tool:{
							ampId:props.selectedAmpId,
							taskid:props.selectedRow.id,
							toolid:editedToolsData?.id,
						}
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}

		const handleSave = async () =>  {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTools`, 
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						oemPn:editedToolsData?.oemPn,
						supplierPn:editedToolsData?.supplierPn,
						optionalPn:editedToolsData?.optionalPn,
						description:editedToolsData?.description,
						remarks:editedToolsData?.remarks,
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}

		const handleUpdate = async () =>  {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTools/${editedToolsData?.id}`, 
				{
					method: 'PATCH',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						oemPn:editedToolsData?.oemPn,
						supplierPn:editedToolsData?.supplierPn,
						optionalPn:editedToolsData?.optionalPn,
						description:editedToolsData?.description,
						remarks:editedToolsData?.remarks,
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}

		const handleDelete = async () => {
			if (selectedRow) {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/DeleteMrTool(ampId=${props.selectedAmpId},taskId=${props.selectedRow?.id},toolId='${selectedRow.id}')`,
					{
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${localStorage.getItem('asid')}`,
							'Content-Type': 'application/json'
						}
					});
				if (response.ok) {
					props.setResponseStatus({ ...props.responseStatus, successfulStatus: 'Delete' });
					//props.reloadTable();
					//setSelectedRow({});
				} else {
					props.setResponseStatus({ ...props.responseStatus, errorStatus: response.status });
				}
			}
		};
	
		// Обработчик нажатия клавиш
		React.useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.key === 'Delete') {
					handleDelete();
				}
			};
	
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [selectedRow]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tools and Equipment Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
						width:'100%'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'30% 30% 20% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='OEM Part Number Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, ml:1}} 
								value={filters.pn?? ''}
								onChange={(event) => setFilters({ ...filters,pn:event.target.value})}
							/>
						</Tooltip>
						<Tooltip 
							title='Description Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.description?? ''}
								onChange={(event) => setFilters({ ...filters, description:event.target.value})}
							/>
						</Tooltip>
						<Button
							variant="outlined"
							color="neutral"
							onClick = {handleSave}
							sx={{
								borderRadius: '0px',
								fontSize: '14px',
								fontWeight:100,
								backgroundColor:'#dedff7',
								border:'2px outset white',
								height:'90%',
								width:'90%',
								minHeight:0,
								mr:1,
								p:0,
								lineHeight:1
							}}
						>
							Save
						</Button>
						<Button
							variant="outlined"
							color="neutral"
							onClick = {handleUpdate}
							sx={{
								borderRadius: '0px',
								fontSize: '14px',
								fontWeight:100,
								backgroundColor:'#dedff7',
								border:'2px outset white',
								height:'90%',
								width:'90%',
								minHeight:0,
								mr:1,
								p:0,
								lineHeight:1
							}}
						>
							Update
						</Button>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:5
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "10px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Any Tools Were Found !</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedRow(row)}
												onDoubleClick={()=>setEditedToolsData(row)}
												style={{ 
													backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
													color:'black',
													cursor:'default'
												}}
											>
												<td style={rowStyle}>{row.oemPn}</td>
												<td style={rowStyle}>{row.supplierPn}</td>
												<td style={rowStyle}>{row.optionalPn}</td>
												<td style={rowStyle}>{row.description}</td>
												<td style={rowStyle}>{row.remarks}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
								
							</Table>
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'16% 16% 16% 16% 16% 20%',
							gridTemplateColumns:'33% 33% 33%',
							position:'relative',
						}}
					>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:5,gridRow:1}}>
							<FormLabel sx={labelStyle}>OEM Part Number:</FormLabel>
							<Input  
								sx={{
									...inputStyle, 
									
								}} 
								value={editedToolsData?.oemPn ?? ''}
								onChange={(event) => setEditedToolsData({...editedToolsData, oemPn:event.target.value})}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:2}}>
							<FormLabel sx={labelStyle}>Supplier Part Number:</FormLabel>
							<Input  
								sx={{
									...inputStyle, 
									
								}} 
								value={editedToolsData?.supplierPn ?? ''}
								onChange={(event) => setEditedToolsData({...editedToolsData, supplierPn:event.target.value})}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:3}}>
							<FormLabel sx={labelStyle}>Optional Part Number:</FormLabel>
							<Input  
								sx={{
									...inputStyle, 
									
								}} 
								value={editedToolsData?.optionalPn ?? ''}
								onChange={(event) => setEditedToolsData({...editedToolsData, optionalPn:event.target.value})}
							/>
						</FormControl>
						<FormControl required sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:4}}>
							<FormLabel sx={labelStyle}>Description:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'70%',
								}}
								value={editedToolsData?.description ?? ''}
								onChange={(event) => setEditedToolsData({...editedToolsData, description:event.target.value})}
							/>
						</FormControl>						
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:4,gridRow:5}}>
							<FormLabel sx={labelStyle}>Remarks:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:'70%',
								}}
								value={editedToolsData?.remarks ?? ''}
								onChange={(event) => setEditedToolsData({...editedToolsData, remarks:event.target.value})}
							/>
						</FormControl>
						<Box sx={{gridColumn:2,gridRow:6}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {handleAdd}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
						</Box>
						<Box sx={{gridColumn:3,gridRow:6}}>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowTabsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:50,
									width:80,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const Jic = () => {
		const [jic, setJic] = React.useState('')
		const [selectedJic, setSelectedJic] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedJic.id){
				setSelectedJic({id:0});
			} else {
				setSelectedJic(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchJic = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/JicProcedure(ampId=${props.selectedAmpId},taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					console.log(response)
					if(response.status !== 204){
						const result = await response.json();
						setJic(result.value);
					}
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props?.selectedAmpId && editedData?.task) fetchJic()
		}, [props?.selectedAmpId, editedData?.task]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						JIC Procedure:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px',
								}}
							>
								{jic === '' ? (
									<Box>No JIC Procedure Were Found !</Box>
								):(
									<tbody>
										<tr
											onClick={() => handleRowSelection(jic)}
											style={{ 
												backgroundColor: selectedJic=== jic ? '#c0c0ff' : 'transparent',
												color:'black',
											}}
										>
											<td style={rowStyle}>{jic}</td>
											<td style={{width:'100%'}}></td>
										</tr>
									</tbody>
								)}
								
							</Table>
						</Box>
					</Grid>
					<Button
						onClick={()=>setShowTabsEditore(true)}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const JicEditor = () => {
		const [filters, setFilters] = React.useState({pn:'', description:''})
		const [editedSpecialInspData, setEditedSpecialInspData] = React.useState({pn:'', description:'', qty:1, materialType:'', unit:'', alt:false})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [inspectionTypesList, setInspectionTypesList] = React.useState([])

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogAcTypes/GetWithAcType(partNumber='${filters?.pn ?? '%'}',description='${filters?.description ?? '%'}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			//fetchTableData ();
		}, [filters]);

		//Запрос списка Units
		React.useEffect(() => {
			const fetchUnitList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/StoreUnits`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setInspectionTypesList(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchUnitList ();
		}, []);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						JIC Procedure Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateRows:'80% 20%',
						gridTemplateColumns:'auto 88px 88px 88px',
						width:'100%',
						pt:1.5
					}}
				>
					<Box
						sx={{
							border:'1px solid black',
							height:'98%',
							mx:1,
							mb:0.3,
							borderRadius:'0px',
							bgcolor:'white',
							overflow:'auto',
							gridRow:1, 
							gridColumnStart:1, 
							gridColumnEnd:5
						}}
					>
						<Table
							aria-labelledby="tableTitle"
							borderAxis="none"
							stickyHeader
							hoverRow
							sx={{
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								"--TableCell-height": "10px",
								'--TableCell-paddingX': '5px',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px',
							}}
						>
							{tableData?.length === 0 ? (
								<Box>No Materials Were Found !</Box>
							):(
								<tbody>
									{tableData.map((row) => (
										<tr
											key={row.pn}
											onClick={() => setSelectedRow(row)}
											onDoubleClick={()=>setEditedSpecialInspData(row)}
											style={{ 
												backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
												color:'black',
												cursor:'default'
											}}
										>
											<td style={rowStyle}>{row.pn}</td>
											<td style={rowStyle}>{row.description}</td>
											<td style={rowStyle}>{row.ata}</td>
											<td style={rowStyle}>{row.acType}</td>
											<td style={{width:'100%'}}></td>
										</tr>
									))}
								</tbody>
							)}
							
						</Table>
					</Box>
					<Box sx={{gridColumn:2,gridRow:2}}>
						<Button
							variant="outlined"
							color="neutral"
							//onClick = {draftAMP}
							sx={{
								borderRadius: '0px',
								fontSize: '14px',
								fontWeight:100,
								backgroundColor:'#dedff7',
								border:'2px outset white',
								height:50,
								width:80,
								display:'flex',
								flexDirection:'column',
								mr:1
							}}
						>
							<img 
								width='27px' 
								height='27px' 
								src="/images/OUTLOOK7.ico" 
								alt="" 
							/>
							Delete
						</Button>
					</Box>
					<Box sx={{gridColumn:3,gridRow:2}}>
						<Button
							variant="outlined"
							color="neutral"
							//onClick = {draftAMP}
							sx={{
								borderRadius: '0px',
								fontSize: '14px',
								fontWeight:100,
								backgroundColor:'#dedff7',
								border:'2px outset white',
								height:50,
								width:80,
								display:'flex',
								flexDirection:'column',
								mr:1
							}}
						>
							<img 
								width='27px' 
								height='27px' 
								src="/images/OUTLOOK7.ico" 
								alt="" 
							/>
							Update
						</Button>
					</Box>
					<Box sx={{gridColumn:4,gridRow:2}}>
						<Button
							variant="outlined"
							color="neutral"
							//onClick = {draftAMP}
							sx={{
								borderRadius: '0px',
								fontSize: '14px',
								fontWeight:100,
								backgroundColor:'#dedff7',
								border:'2px outset white',
								height:50,
								width:80,
								display:'flex',
								flexDirection:'column',
								mr:1
							}}
						>
							<img 
								width='27px' 
								height='27px' 
								src="/images/OUTLOOK7.ico" 
								alt="" 
							/>
							Close
						</Button>
					</Box>
				</Box>
			</Box>
		)
	}

	const Controls = () => {
		const [tools, setTools] = React.useState([])
		const [selectedTools, setSelectedTools] = React.useState({id:0})
		
		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.id === selectedTools.id){
				setSelectedTools({id:0});
			} else {
				setSelectedTools(row);
			}  
		};
		
		React.useEffect(() => {
			const fetchControls = async () => {
				const taskId = taskIdList?.find(task => task.task === editedData?.task)?.id
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/Controls(taskId=${taskId})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					if(response.status !== 204){
						const result = await response.json();
						setTools(result.value);
					}
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(editedData?.task) fetchControls()
			setShowTabsEditore(false)
		}, [editedData?.task]);
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Controls:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid container spacing={0} xs={10} sx={{pl:1,pt:1}}>
						<Grid xs={12}>
							<Tooltip title='Quality Engineer'>
								<Checkbox 
									label=':Q - Quality Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='A1 Technician'>
								<Checkbox 
									label=':A1 - A1 Technician'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='B1 Engineer'>
								<Checkbox 
									label=':B1 - B1 Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='B2 Engineer'>
								<Checkbox 
									label=':B2 - B2 Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Grid xs={12}/>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Save
							</Button>
						</Grid>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const AssosiatedTask = () => {
		const [filters, setFilters] = React.useState({isTask:true, filter:'',})
		const [editedDocRefData, setEditedDocRefData] = React.useState({docType:'', reference:''})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [docTypesList, setDocTypesList] = React.useState([])

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const endpoint = filters.isTask?
				`AmpMrs/AssociatedTasks(ampId=${props.selectedAmpId},mainTaskId=${props.selectedRow?.id},taskfilter='${filters.filter}'`:
				`AmpMrs/AssociatedEcs(ampId=${props.selectedAmpId},mainTaskId=${props.selectedRow?.id},ecFilter='${filters.filter}'`
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/${endpoint})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props.selectedRow?.id) fetchTableData ();
		}, [filters,props.selectedRow?.id]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
					ml:0.6
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-8,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14,
							lineHeight:1
						}}
					>
						Associated Task:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.5, px:1 }}>
				<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
						<RadioGroup orientation="horizontal">
							<Radio 
								value="Task" 
								label="Task" 
								variant="outlined" 
								color='neutral'
								sx={{
									'--Radio-size':'14px',
									gap:0.5,
									fontSize:14
								}}
								checked={filters.isTask}
								onChange={()=>setFilters({...filters, isTask:true})}
							/>
							<Radio 
								value="EC" 
								label="EC" 
								variant="outlined" 
								color='neutral'
								sx={{
									'--Radio-size':'14px',
									gap:0.5,
									fontSize:14
								}}
								checked={!filters.isTask}
								onChange={()=>setFilters({...filters, isTask:false})}
							/>
						</RadioGroup>
					</Grid>
					<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
						<Typography>
							Filter:
						</Typography>
					</Grid>
					<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
						<Tooltip 
							title={filters.isTask?'Task Filter':'EC Filter'}
						>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Button
							//onClick={handleConfirm}
							color='neutral'
							sx={{
								borderRadius:0,
								height: 25, 
								minHeight: 0,
								bgcolor:'#d6d3ce',
								color:'black',
								border:'2px outset grey',
							}}
						>
							Add
						</Button>
					</Grid>

					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'86px',
								mx:0.5,
								mt:0.1,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Associated Task Were Found!</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.slaveTaskId}
												//onClick={() => handleRowSelection(row)}
												style={{ 
													//backgroundColor: selectedDocRef === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>
													<Checkbox 
														variant="outlined"
														color="neutral"
														sx={{
															fontSize:'14px',
															'--Checkbox-size':'12px',
															gap:0,
															'& span': {
																borderRadius: '0px',
																borderWidth:'1px',
																borderColor:'black',
																color:'black'
															}, 
														}}
														checked={row.associated}
														//onChange={(event) => handleChange('shift', event.target.checked)}
													/>
												</td>
												<td style={rowStyle}>{row.slaveTaskId}</td>
												<td style={rowStyle}>{row.slaveTask}</td>
												<td style={rowStyle}>{row.type}</td>
												<td style={rowStyle}>{row.taskTitle}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
						</Box>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const RelatedTask = () => {
		const [filters, setFilters] = React.useState({isTask:true, filter:'',})
		const [editedDocRefData, setEditedDocRefData] = React.useState({docType:'', reference:''})
		const [selectedRow, setSelectedRow] = React.useState({})
		const [tableData, setTableData] = React.useState([])
		const [docTypesList, setDocTypesList] = React.useState([])

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const endpoint = filters.isTask?
				`AmpMrs/RelatedTasks(ampId=${props.selectedAmpId},mainTaskId=${props.selectedRow?.id},taskfilter='${filters.filter}'`:
				`AmpMrs/RelatedEcs(ampId=${props.selectedAmpId},mainTaskId=${props.selectedRow?.id},ecFilter='${filters.filter}'`
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/${endpoint})`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			if(props.selectedRow?.id) fetchTableData ();
		}, [filters,props.selectedRow?.id]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
					mr:0.6
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-8,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14,
							lineHeight:1
						}}
					>
						Related Task or EC:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.5, px:1 }}>
				<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
						<RadioGroup orientation="horizontal">
							<Radio 
								value="Task" 
								label="Task" 
								variant="outlined" 
								color='neutral'
								sx={{
									'--Radio-size':'14px',
									gap:0.5,
									fontSize:14
								}}
								checked={filters.isTask}
								onChange={()=>setFilters({...filters, isTask:true})}
							/>
							<Radio 
								value="EC" 
								label="EC" 
								variant="outlined" 
								color='neutral'
								sx={{
									'--Radio-size':'14px',
									gap:0.5,
									fontSize:14
								}}
								checked={!filters.isTask}
								onChange={()=>setFilters({...filters, isTask:false})}
							/>
						</RadioGroup>
					</Grid>
					<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
						<Typography>
							Filter:
						</Typography>
					</Grid>
					<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
						<Tooltip 
							title={filters.isTask?'Task Filter':'EC Filter'}
						>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Button
							//onClick={handleConfirm}
							color='neutral'
							sx={{
								borderRadius:0,
								height: 25, 
								minHeight: 0,
								bgcolor:'#d6d3ce',
								color:'black',
								border:'2px outset grey',
							}}
						>
							Add
						</Button>
					</Grid>

					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid black',
								height:'86px',
								mx:0.5,
								mt:0.1,
								borderRadius:'0px',
								backgroundColor:'#c6ffff',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								{tableData?.length === 0 ? (
									<Box>No Related Task Were Found!</Box>
								):(
									<tbody>
										{tableData.map((row) => (
											<tr
												key={row.slaveTaskId}
												//onClick={() => handleRowSelection(row)}
												style={{ 
													//backgroundColor: selectedDocRef === row ? '#c0c0ff' : 'transparent',
													color:'black',
												}}
											>
												<td style={rowStyle}>
													<Checkbox 
														variant="outlined"
														color="neutral"
														sx={{
															fontSize:'14px',
															'--Checkbox-size':'12px',
															gap:0,
															'& span': {
																borderRadius: '0px',
																borderWidth:'1px',
																borderColor:'black',
																color:'black'
															}, 
														}}
														checked={row.associated}
														//onChange={(event) => handleChange('shift', event.target.checked)}
													/>
												</td>
												<td style={rowStyle}>{row.slaveTaskId}</td>
												<td style={rowStyle}>{row.slaveTask}</td>
												<td style={rowStyle}>{row.type}</td>
												<td style={rowStyle}>{row.taskTitle}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
						</Box>
					</Grid>
				</Grid>
			</Box>
		)
	}
	
	return (
		<Box sx={{width:'100%'}}>
			<MoveTaskWnd
				setShowMoveTaskWnd={setShowMoveTaskWnd}
				showMoveTaskWnd={showMoveTaskWnd}
				URL={props.URL}
				selectedRow={props.selectedRow}
				selectedACFamily={props.selectedACFamily}
				selectedOperator={props.selectedOperator}
			/>
			<Typography 
				sx={{
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Maintenance Requirements Editor:
			</Typography>
			<Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={true}
						onClick={handleAdd}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleUpdate}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleDelete}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Change Check Settings (AMP Plan)'>
					<IconButton 
						variant='plain'
						onClick={()=>{setShowMoveTaskWnd(true)}}
						disabled={props.selectedRow?.id ===0}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/check.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Box
					sx={{
						display: 'flex',
						alignItems:'center',
						ml:'auto'
					}}
				>
					<Typography sx={labelStyle}>
						Rev. Date:
					</Typography>
					<Input
						type="date"
						sx={inputStyle}
						value={editedData?.employedSince || ''}
						onChange={(event) => handleChange('employedSince', event.target.value)}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					top:'70px',
					overflow:'auto',
				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1 }}>
					<Grid xs={4}>
						<Tooltip title='Base Maintenance Task or Line Maintenance Task'>
							<Checkbox 
								label=':BASE'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									fontWeight:'bold',
									color:'#3c0203',
									'& span': {
										borderRadius: '0px',
										borderWidth:'1px',
										borderColor:'black',
										color:'black'
									}, 
									position:'absolute',
									left:4,
								}}
								checked={editedData.base === 'Y' ? true : false}
								onChange={(event) => handleChange('base', event.target.checked ? 'Y' : 'N')}
							/>
						</Tooltip>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Task ID:</FormLabel>
							<Autocomplete
								disableClearable
								options={taskIdList?.map(data=> ({ label: data.task }))}
								value={{ label: editedData.task ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('task', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Basic Task:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.basicTask ?? ''}
								onChange={(event) => handleChange('basicTask', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={1}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>preFix:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.ata?.split('-')[2] || ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={3}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>ATA:</FormLabel>
							<Box
								sx={{display:'flex', alignItems:'center', justifyContent:'center'}}
							>
								<Input  
									sx={inputStyle} 
									value={editedData?.ata?.split('-')[0] || ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
								-
								<Input  
									sx={inputStyle} 
									value={editedData?.ata?.split('-')[1] || ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
								-
								<Input  
									sx={inputStyle} 
									value={editedData?.ata?.split('-')[2] || ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</Box>
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Task Title:</FormLabel>
							<Textarea 
								maxRows={1}
								defaultValue={props.selectedRow.description}
								sx={{...inputStyle,color:'#000078'}}
								value={editedData?.taskTitle || ''}
								onChange={(event) => handleChange('taskTitle', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Task Description:</FormLabel>
							<Textarea 
								maxRows={1}
								defaultValue={props.selectedRow.description}
								sx={{...inputStyle,color:'#000078', height:60}}
								value={editedData?.taskDescription || ''}
								onChange={(event) => handleChange('taskDescription', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={2}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Task Type:</FormLabel>
							<Autocomplete
								disableClearable
								options={taskTypeList?.map(data=> ({ label: data }))}
								value={{ label: editedData.taskType?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Task Effectivity:</FormLabel>
							<Autocomplete
								disableClearable
								options={taskEffList?.map(data=> ({ label: data.acConfigurationDescription }))}
								value={{ label: editedData.taskEffectivity ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={2}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>MNHR:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.nnhr ?? ''}
								onChange={(event) => handleChange('nnhr', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>JIC:</FormLabel>
							<Textarea 
								maxRows={1}
								defaultValue={props.selectedRow.description}
								sx={{...inputStyle,color:'#000078'}}
								value={editedData?.jic || ''}
								onChange={(event) => handleChange('jic', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={2}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>Main Zone:</FormLabel>
							<Autocomplete
								disableClearable
								options={mainZoneList?.map(data=> ({ label: data.mainZone }))}
								value={{ label: editedData.mainZone ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('mainZone', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>Additional Zones:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.zones ?? ''}
								onChange={(event) => handleChange('zones', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={2}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>MRB Code:</FormLabel>
							<Autocomplete
								disableClearable
								options={mrbCodeList.map(data=> ({ label: data.mrbCode }))}
								value={{ label: editedData.mrbCode ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('mrbCode', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>NOTE:</FormLabel>
							<Textarea 
								maxRows={1}
								defaultValue={props.selectedRow.description}
								sx={{...inputStyle,color:'#000078'}}
								value={editedData?.note || ''}
								onChange={(event) => handleChange('note', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<Tabs
							//value={selectedTab}
							//onChange={(event, newValue) => handleChangeTab(newValue)}
							defaultValue="Int"
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								mx:0.6,
								'--Tabs-spacing': '5px'
							}}
						>
							<TabList >
								<Tooltip title='Interval'>
									<Tab value="Int" sx={tabStyle}>
										<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:greenFlags?.interval ? 'flex':'none'}}/>
										Interval
									</Tab>
								</Tooltip>
								<Tooltip title='Start Threshold'>
									<Tab value="ST" sx={tabStyle}>
										<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:greenFlags?.start ? 'flex':'none'}}/>
										Start Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Finish Threshold'>
									<Tab value="FT" sx={tabStyle}>
										<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:greenFlags?.finish ? 'flex':'none'}}/>
										Finish Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Tolerance Method'>
									<Tab value="T" sx={tabStyle}>
										Tolerance
									</Tab>
								</Tooltip>
								<Tooltip title='Instructions'>
									<Tab value="Ins" sx={tabStyle}>
										<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:greenFlags?.instractions ? 'flex':'none'}}/>
										Instructions
									</Tab>
								</Tooltip>
								<Tooltip title='POST THRESHOLD INTERVALS FOR STRUCTURES AND ZONAL TASKS'>
									<Tab value="PT" sx={tabStyle}>
										<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:greenFlags?.post ? 'flex':'none'}}/>
										Post Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Lower Utilization Maintenance Program'>
									<Tab value="LUMP" sx={tabStyle}>
										LUMP
									</Tab>
								</Tooltip>
							</TabList>
							<TabPanel value="Int" sx={{p:'0px'}}>
								<IntervalEditore/>
							</TabPanel>
							<TabPanel value="ST" sx={{p:'0px'}}>
								<StartThresholdEditore/>
							</TabPanel>
							<TabPanel value="FT" sx={{p:'0px'}}>
								<FinishThresholdEditore/>
							</TabPanel>
							<TabPanel value="T" sx={{p:'0px'}}>
								<ToleranceEditore/>
							</TabPanel>
							<TabPanel value="Ins" sx={{p:'0px'}}>
								<InstructionsEditore/>
							</TabPanel>
							<TabPanel value="PT" sx={{p:'0px'}}>
								<PostThresholdEditore/>
							</TabPanel>
							<TabPanel value="LUMP" sx={{p:'0px'}}>
								<LUMPEditore/>
							</TabPanel>
						</Tabs>
					</Grid>
					
					<Grid xs={12}>
						<Tabs
							//value={selectedTab}
							//onChange={(event, newValue) => handleChangeTab(newValue)}
							defaultValue="DR"
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								mx:0.6,
								mt:0.5,
								'--Tabs-spacing': '5px'
							}}
						>
							<TabList >
								<Tooltip title='Document Reference'>
									<Tab value="DR" sx={tabStyle}>
									<FlagIcon sx={{color:'green', height:'14px', lineHeight:1, display:'none'}}/>Doc. Ref
									</Tab>
								</Tooltip>
								<Tooltip title='Special Inspection is Required'>
									<Tab value="SI" sx={tabStyle}>
										Special Insp.
									</Tab>
								</Tooltip>
								<Tooltip title='Panels'>
									<Tab value="P" sx={tabStyle}>
										Panels
									</Tab>
								</Tooltip>
								<Tooltip title='Materials'>
									<Tab value="M" sx={tabStyle}>
										Materials
									</Tab>
								</Tooltip>
								<Tooltip title='Tools'>
									<Tab value="T" sx={tabStyle}>
										Tools
									</Tab>
								</Tooltip>
								<Tooltip title='JIC Procedure'>
									<Tab value="JICP" sx={tabStyle}>
										JIC Procedure
									</Tab>
								</Tooltip>
								<Tooltip title='Job Card Control by'>
									<Tab value="C" sx={tabStyle}>
										Control
									</Tab>
								</Tooltip>
							</TabList>
							<TabPanel value="DR" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<DocRef/>
								):(
									<DocRefEditor/>
								)}
								
							</TabPanel>
							<TabPanel value="SI" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<SpecialInsp/>
								):(
									<SpecialInspEditor/>
								)}
							</TabPanel>
							<TabPanel value="P" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<Panels/>
								):(
									<PanelsEditor/>
								)}
							</TabPanel>
							<TabPanel value="M" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<Materials/>
								):(
									<MaterialsEditor/>
								)}
							</TabPanel>
							<TabPanel value="T" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<Tools/>
								):(
									<ToolsEditor/>
								)}
							</TabPanel>
							<TabPanel value="JICP" sx={{p:'0px'}}>
								{!showTabsEditore ? (
									<Jic/>
								):(
									<JicEditor/>
								)}
							</TabPanel>
							<TabPanel value="C" sx={{p:'0px'}}>
								<Controls/>
							</TabPanel>
						</Tabs>
					</Grid>
					{!showTabsEditore && (
						<>
						<Grid xs={6}>
							<AssosiatedTask/>
						</Grid>
						<Grid xs={6}>
							<RelatedTask/>
						</Grid>
						</>
					)}
				</Grid>
			</Box>
		</Box>
	);
}