import React, { useState, useEffect } from "react";
import buildQuery from "odata-query";
import Table from "@mui/joy/Table";
import Checkbox from "@mui/joy/Checkbox";
import CircularProgress from "@mui/joy/CircularProgress";
import Header from "../../Layout/Header";
import Application from "../../../Application";
import { CssVarsProvider } from "@mui/joy/styles";
import CheckBoxStyle from "../../../Theme/CheckBoxStyle.jsx";
import { ToastContainer, toast, Zoom, ToastOptions} from "react-toastify";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Stack } from "@mui/material";
import Tooltip from "@mui/joy/Tooltip";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import { styled } from "@mui/joy";
import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormControl from "@mui/joy/FormControl";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Report from "./Report.tsx";
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import "./Dto/Model.tsx"

export default function Main() {
	const application = new Application();
	const baseUrl = application.getDestinyHeraldUrl();
	const [about, setAbout] = useState<AboutDto | null>();
	const [isShowSpinner, setIsShowSpinner] = useState<number>(0);
	const [permission, setPermission] = useState<string>("");
	const [selectedScheduler, setSelectedScheduler] = useState<SchedulerDto | null>(null);
	const [editableScheduler, setEditableScheduler] = useState<SchedulerDto | null>(null);
	const [schedulers, setSchedulers] = useState<Array<SchedulerDto>>([]);
	const [isOpenDeletionConfirmationDialog, setIsOpenDeletionConfirmationDialog] = useState<boolean>(false);
	const [isShowEditor, setIsShowEditor] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const headers = { "Authorization": "Bearer " + localStorage.getItem("asid"), "Content-Type": "application/json" };
	const [timerId, setTimerId] = useState<any>(null);
	const [changes, setChanges] = useState<boolean>(false);
	const [isShowReportWindow, setIsShowReportWindow] = useState<boolean>(false);
	const [isExistsReport, setIsExistsReport] = useState<boolean>(false);
	document.title = "Destiny Herald";

	const cronTooltipText = <div style={{ display: "flex", flexDirection: "column", maxWidth: "500px", justifyContent: "center"}}>
		<Table>
			<thead>
				<tr>
					<th style={{width: "30px"}}>sec.</th>
					<th style={{width: "30px"}}>min.</th>
					<th style={{width: "35px"}}>hour</th>
					<th>day of month</th>
					<th style={{width: "45px"}}>month</th>
					<th>day of week</th>
					<th style={{width: "70px"}}>year</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>0-59</td>
					<td>0-59</td>
					<td>0-23</td>
					<td>1-31</td>
					<td>1-12</td>
					<td>SUN-SAT</td>
					<td>1970-2099</td>
				</tr>
			</tbody>

		</Table>
	  </div>


	const toastOptions: ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	const styleSelectedRowTable = {
		"--TableCell-dataBackground": "var(--TableCell-selectedBackground)",
		"--TableCell-headBackground": "var(--TableCell-selectedBackground)"
	}

	const styleTableHeader = {
		color: "white",
		fontWeight: "normal",
		fontSize: "15px",
		padding: "6.5pt",
		width: "auto",
		whiteSpace: "nowrap",
	}

	const styleTable = {
		"--TableCell-headBackground": "#3B3D40",
		"--TableCell-paddingY": "3pt",
		"--TableCell-height": "16pt",
		"--TableCell-paddingX": ".5rem",
		"--TableCell-borderColor": "#C0BFBF",
		"--joy-palette-background-level2": "#EFEFEF",
		"--Table-headerUnderlineThickness": "1px",
		"--TableRow-hoverBackground": "#C3D0EA",
		tableLayout: "auto",
		"&:hover": {
			cursor: "default"
		}
	};

	const styleToolbarButton = {
		color: "#6B6B6B",
		"&:hover": {
			color: "#276CF2",
			background: "none"
		}
	}

	useEffect(() => {
		async function onMount() {
			setAbout(await getVersion());
			setSchedulers(await getSchedulers());
			setPermission(await getPermission("Destiny Herald", "Schedulers"));
		}
			
		onMount();
	}, []);

	async function getPermission(moduleName: string, windowName: string) {
		try {	
			setIsShowSpinner(value => value + 1);

			const filter = [
				{ module: moduleName },
				{ window: windowName }
			];
			
			let query = buildQuery({filter});

			let url = application.getAdminUrl() + "self/permissions" + query;

			let response = await fetch(url, { method: "GET", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}

				let answer = await response.json();
				
				toast.error(<div>Failed get permissions<br/><hr/>{answer?.message}</div>, toastOptions);
				return "";
			}

			let content = await response.json();

			if (content.value.length > 0) {
				return content.value[0].access;
			}
			else {
				toast.error(<div>Error<br/><hr/>Missing permissions.</div>, toastOptions);
				return "";
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get permissions.</div>, toastOptions);
			return "";
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function getVersion() {
		try {
			setIsShowSpinner(value => value + 1);
			
			var url = new URL("about", baseUrl);
			let response = await fetch(url);
			
			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}

				let answer = await response.json();
				
				toast.warn(<div>Warn<br/><hr/>Failed get version number.<br/>{answer?.message}</div>, toastOptions);
				
				return "";
			}
			
			let content = await response.json();
			return content;
		}
		catch {
			toast.warn(<div>Warn<br/><hr/>Failed get version number.</div>, toastOptions);			
			return "";
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function getSchedulers(name: string = "") {
		try {
			setIsShowSpinner(value => value + 1);

			let query: string = "";
			
			if (name) {
				const filter = `startswith(name, '${name}')`;
				query = buildQuery({filter});
			}

			var url =
				new URL("schedulers", baseUrl) + query;

			let response = await fetch(url,
				{ method: "GET", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer?.message}</div>, toastOptions);

				return [];
			}

			let content = await response.json();
			return content.value;
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get schedulers</div>, toastOptions);
			return [];
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function showEditor() {
		setIsShowEditor(value => value = !value);
	}

	async function addScheduler() {
		try {
			setIsShowSpinner(value => value + 1);

			var url = new URL("schedulers", baseUrl);
			
			let newSchedule = {...editableScheduler};
			
			newSchedule.name = newSchedule.name?.trim();
			newSchedule.cronExpression = newSchedule.cronExpression?.trim();

			delete newSchedule["id"];
			delete newSchedule["isStarted"];
			delete newSchedule["lastRun"];

			let response = await fetch(url, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(newSchedule)
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				toast.success("Schedule added.", toastOptions);
				setSchedulers(await getSchedulers());
				setSelectedScheduler(null);
				setEditableScheduler(null);

				setChanges(false);
			}
			else {
				let content = await response.json();

				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to add scheduler.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to add scheduler.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function updateScheduler() {
		try {
			let updates = {...editableScheduler} as SchedulerDto;
			
			updates.name = updates.name?.trim();
			updates.cronExpression = updates.cronExpression?.trim();

			setIsShowSpinner(value => value + 1);
			var url = new URL("schedulers/" + selectedScheduler?.id, baseUrl);
			let response = await fetch(url, {
				method: "PATCH",
				headers: headers,
				body: JSON.stringify(updates)
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				toast.success("Changes saved.", toastOptions);

				setEditableScheduler(updates);

				//#region Поиск элемента и смена состояния
				
				let item = schedulers.find(i => i.id === editableScheduler?.id);
				
				if (item) {
					if (item.name != updates?.name) item.name = updates?.name ?? "";
					if (item.cronExpression != updates?.cronExpression) item.cronExpression = updates?.cronExpression ?? "";
					if (item.reportId != updates?.reportId) item.reportId = updates?.reportId ?? "";

					setSelectedScheduler(item);
				}

				//#endregion

				setChanges(false);
				setIsExistsReport(!!updates.reportId);
			}
			else {
				let content = await response.json();

				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to save changes.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to save changes.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function execOnce() {
		setIsShowSpinner(value => value + 1);
		
		var url = new URL("schedulers/" + selectedScheduler?.id + "/ExecOnce", baseUrl);

		fetch(url, { method: "POST", headers: headers })
			.then(async (response) => {
				if (response.status === 401) {
					Application.logout();
				}

				if (response.ok) {
					toast.success("One-time execution started.", toastOptions);
				}
				else {
					let content = await response.json();

					if (content?.message) {
						toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
					}
					else {
						toast.error(<div>Error<br/><hr/>Failed to started schedule.<br/>Code: {response.status}</div>, toastOptions);
					}
				}
			})
			.catch(() => {
				toast.error(<div>Error<br/><hr/>Failed to started schedule.</div>, toastOptions);
			})
			.finally(() => {
				setIsShowSpinner(value => value - 1);
			});
		
	}

	async function deleteScheduler() {
		try {
			setIsShowSpinner(value => value + 1);

			var url = new URL("schedulers/" + selectedScheduler?.id, baseUrl);

			let response = await fetch(url,
				{ method: "DELETE", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer.Message}</div>, toastOptions);
				return;
			}

			let newList =
				schedulers.filter(i => i !== selectedScheduler);
			
			setSelectedScheduler(null);
			setEditableScheduler(null);
			setIsExistsReport(false);

			setSchedulers(newList);
		}
		catch {
			toast.error("Failed to delete.", toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function stopScheduler() {
			setIsShowSpinner(value => value + 1);

			var url = new URL(`schedulers/${selectedScheduler?.id}/stop`, baseUrl);

			fetch(url, { method: "POST", headers: headers })
				.then(async (response) => {
					if (response.status === 401) {
						Application.logout();
					}

					if (response.ok) {
						toast.success("Schedule stopped.", toastOptions);

						//#region Поиск элемента и смена состояния
						
						let item = schedulers.find(i => i.id === selectedScheduler?.id);
						
						if (item) {
							item.isStarted = !item.isStarted;	
						}

						//#endregion
					}
					else {
						let content = await response.json();

						if (content?.message) {
							toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
						}
						else {
							toast.error(<div>Error<br/><hr/>Failed to stop schedule.<br/>Code: {response.status}</div>, toastOptions);
						}
					}
				})
				.catch(error => {
					toast.error(<div>Error<br/><hr/>Failed to stop schedule.</div>, toastOptions);
				})
				.finally(() => {
					setIsShowSpinner(value => value - 1);
				});
	}

	async function startScheduler() {
		setIsShowSpinner(value => value + 1);

		var url = new URL(`schedulers/${selectedScheduler?.id}/start`, baseUrl);

		let response = fetch(url, { method: "POST", headers: headers })
			.then(async (response) => {
				if (response.status === 401) {
					Application.logout();
				}

				if (response.ok) {
					toast.success("Schedule started.", toastOptions);
					
					//#region Поиск элемента и смена состояния
						
					let item = schedulers.find(i => i.id === selectedScheduler?.id);
						
					if (item) {
						item.isStarted = !item.isStarted;	
					}

					//#endregion
				}
				else {
					let content = await response.json();
					if (content?.message) {
						toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
					}
					else {
						toast.error(<div>Error<br/><hr/>Failed to start schedule.<br/>Code: {response.status}</div>, toastOptions);
					}
				}
			})
			.catch(error => {
				toast.error(<div>Error<br/><hr/>Failed to start schedule.</div>, toastOptions);
			})
			.finally(() => {
				setIsShowSpinner(value => value - 1);
			});
	}

	function importSchedule(event) {
		try {
			for (let file of event?.target?.files ?? []) {
				const fileReader = new FileReader();
				
				fileReader.onerror = () => {
					toast.error("Error Reading File.", toastOptions);
				}

				fileReader.onload = async () => {
					let url = new URL("schedulers/import", baseUrl);

					let response = await fetch(url, { method: "POST",
						headers: headers,
						body: fileReader.result});

					if (!response.ok) {
						if (response.status === 401) {
							Application.logout();
						}
						
						let errorRequest = await response.json();

						if (errorRequest.message) {
							toast.error(errorRequest.message, toastOptions);
						}
						else {
							toast.error("Import Error.", toastOptions);
						}	
					}
					else {
						toast.success("Import Completed Successfully.", toastOptions);
						setSchedulers(await getSchedulers());
					}
				}

				fileReader.readAsText(file);
				event.target.value = "";
				break;
			}
		}
		catch {
			toast.error("Import Error.", toastOptions);
		}
	}

	function onRowClick(evernt, item: SchedulerDto) {
		let value;

		if (selectedScheduler?.id === item.id) {
			value = null;
			setIsExistsReport(false);
		}
		else {
			value = item;
			setIsExistsReport(value?.reportId);
		}

		setSelectedScheduler(value);
		setEditableScheduler(value === false ? value : {...value});
		setChanges(false);
	}

	function getRowColor(index: number, item: SchedulerDto) {
		if (selectedScheduler?.id === item.id) return "#C8C8C8";
		if (item.isStarted ?? false) return "#D9EAD3";
		if (index % 2 != 0) return "#EFEFEF"
		return "white";
	}

	function getBorderColor(index: number, item: SchedulerDto) {
		if (selectedScheduler?.id === item.id) return "white";
		return "#C0BFBF";
	}

	function debounce (fn: () => void, d: number) {
		if (timerId) clearTimeout(timerId);	
		setTimerId(setTimeout(fn, d));
	}

	function onChangeSearchString(e) {
		setSearchString(e.target.value);
		
		debounce(async () => {			
			setSchedulers(await getSchedulers(e.target.value));
		}, 500);
	}

	const VisuallyHiddenInput = styled("input")`
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		left: 0;
		white-space: nowrap;
		width: 1px;`;

	function isButtonPlayDisabled(): boolean {
		return selectedScheduler == null ||
			selectedScheduler == undefined ||
			selectedScheduler.isStarted ||
			permission != "Full Control";
	}

	function onEditScheduler(name: string, value: string) {
		let modifiedScheduler = {...editableScheduler, [name]: value} as SchedulerDto;
		
		setChanges(modifiedScheduler.name != selectedScheduler?.name ||
			modifiedScheduler.cronExpression != selectedScheduler?.cronExpression);

		setEditableScheduler(modifiedScheduler);
	}

	function addCan() : boolean {
		return !!editableScheduler?.name &&
			!!editableScheduler?.cronExpression &&
			permission == "Full Control";
	}

	function updateCan() : boolean {
		return changes &&
			!selectedScheduler?.isStarted &&
			(permission == "Full Control" || permission == "Modify Only") &&
			!!editableScheduler?.id &&
			!!editableScheduler?.name &&
			!!editableScheduler?.cronExpression;
	}
	
	function onSelectReportCan() : boolean {
		return !!selectedScheduler;
	}

	function onSelectReprot(selectedReprotId: string) {
		setEditableScheduler({...editableScheduler, reportId: selectedReprotId ? selectedReprotId : null} as SchedulerDto);
		if (selectedScheduler?.reportId != selectedReprotId) {
			setChanges(true);
		}
	}

	function importCan() : boolean {
		return permission == "Full Control";
	}

	function deleteCan() {
		return selectedScheduler &&
		!selectedScheduler.isStarted &&
		permission == "Full Control";
	}

	function exportCan() : boolean {
		return !!selectedScheduler;
	}

	async function exportToFile() {
		try {
			setIsShowSpinner(value => value + 1);

			var url = new URL("schedulers/" + selectedScheduler?.id + "/export", baseUrl);

			let response = await fetch(url, {
				method: "GET",
				headers: headers
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {	
				let blob = await response.blob();
				let a = document.createElement("a");
				a.href = URL.createObjectURL(blob);
				a.download = "scheduler_" + selectedScheduler?.name;
				a.click();
			}
			else {
				let content = await response.json();

				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Export error.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Export error.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function dateFormatting(value: string) : string {
		return new Date(value).toLocaleString()
			.toString()
			.replace(',', '');
	}

	function stopCan() {
		return selectedScheduler?.isStarted &&
		permission == "Full Control";
	}

	return (
		<div style={{height: "100dvh", width: "100vw", whiteSpace: "nowrap"}}>
			<div style={{display: "grid", height: "100%", transitionDuration: "300ms", gridTemplateColumns: isShowEditor ? "1fr 25%" : "1fr 0", gridTemplateRows: "auto auto 1fr auto auto", gridTemplateAreas: "'header header' 'toolbar editor' 'table editor' 'footer footer'"}}>

				{/* Header */}
				<div style={{gridArea: "header"}}>
					<Header title={document.title} version={about?.version} />
				</div>
				
				{/* Toolbar */}
				<div style={{gridArea: "toolbar", padding: ".3rem 1.3rem .3rem .5rem", background: "#FBFCFE", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between"}}>
					<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
						<h3 style={{color: "#6B6B6B", margin: ".4rem 0 0 .5rem", flexWrap: "nowrap"}}>Schedules</h3>
					</Stack>
						
					<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end"}}>
						<Input value={searchString} onChange={onChangeSearchString} endDecorator={<SearchIcon />} sx={{color: "#276CF2", fontWeight: "600"}} />
								
						{
							isShowEditor == false &&

							<>
								<Tooltip title="START" variant="solid" placement="top">
									<IconButton onClick={startScheduler} disabled={isButtonPlayDisabled()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
										<PlayArrowOutlinedIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="STOP" variant="solid" placement="top">
									<IconButton onClick={stopScheduler} disabled={!stopCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
										<StopCircleOutlinedIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="EXECUTE ONCE" variant="solid" placement="top">
									<IconButton onClick={execOnce} disabled={!selectedScheduler} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
										<PlayArrowOutlinedIcon />
										<StopRoundedIcon sx={{marginLeft: "-10px"}} />
									</IconButton>
								</Tooltip>
								
								<Tooltip title="DELETE" variant="solid" placement="top">
									<IconButton onClick={() => setIsOpenDeletionConfirmationDialog(true)} disabled={!deleteCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#FF6C37"}}>
										<DeleteForeverOutlinedIcon />
									</IconButton>
								</Tooltip>
							</>
						}

						<Tooltip title="IMPORTING SCHEDULE FROM FILE" variant="solid" placement="top">
							<IconButton disabled={!importCan()} component={importCan() ? "label" : "button"} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
								<DownloadOutlinedIcon />
								<VisuallyHiddenInput id="file-import" type="file" accept="application/json" onChange={importSchedule} />
							</IconButton>
						</Tooltip>

						<Tooltip title="EXPORTING SCHEDULE TO FILE" variant="solid" placement="top">
							<IconButton onClick={exportToFile} disabled={!exportCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
								<UploadOutlinedIcon />
							</IconButton>
						</Tooltip>
						
						{
							isShowEditor == false &&

							<Tooltip title={"SHOW EDITOR"} variant="solid" placement="top">
								<IconButton onClick={showEditor} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2"}}>
									<EditNoteRoundedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
								</IconButton>
							</Tooltip>
						}
					</Stack>
				</div>
				
				{/* Table */}
				<div style={{overflow: "auto", gridArea: "table", whiteSpace: "nowrap"}}>
						<Table borderAxis="both" size="sm" stickyHeader sx={{...styleTable, overflow: "auto"}} hoverRow={true} >
							<thead>
								<tr>
									<th style={styleTableHeader}>Name:</th>
									<th style={styleTableHeader}>Cron Expression:</th>
									<th style={styleTableHeader}>Started:</th>
									<th style={styleTableHeader}>Last Run:</th>
								</tr>
							</thead>
							<tbody>
								{schedulers.map((row, index) => (
									<tr key={row.id} onClick={(event) => onRowClick(event, row)} style={{background: getRowColor(index, row)}} >
										<td>{row.name}</td>
										<td style={{borderLeft: "1px solid " + getBorderColor(index, row)}}>{row.cronExpression}</td>
										<td style={{textAlign: "center", borderLeft: "1px solid " + getBorderColor(index, row)}}>
											<CssVarsProvider theme={CheckBoxStyle}>
												<Checkbox checked={row.isStarted ?? false} />
											</CssVarsProvider>
										</td>
										<td style={{borderLeft: "1px solid " + getBorderColor(index, row)}}>{!!row.lastRun ? dateFormatting(row.lastRun) : "never"}</td>
									</tr>
								))}
							</tbody>
						</Table>
				</div>
				
				{/* Editor */}
				<div style={{gridArea: "editor", overflow: "auto", boxShadow: "-7px 0px 8px -6px rgba(17, 17, 17, 0.22)"}}>
					{/* Toolbar */}
					<div style={{padding: ".3rem 1.3rem .3rem .5rem", gridArea: "toolbar", display: "grid", gridTemplateColumns: "auto 1fr"}}>
						<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
							<Tooltip title="ADD" variant="solid" placement="top">
								<IconButton onClick={addScheduler} disabled={!addCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<AddCircleOutlineOutlinedIcon />
								</IconButton>
							</Tooltip>

							<Tooltip title="UPDATE" variant="solid" placement="top">
								<IconButton onClick={updateScheduler} disabled={!updateCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<SaveOutlinedIcon />
								</IconButton>
							</Tooltip>

							<Tooltip title="RESET" variant="solid" placement="top">
								<IconButton onClick={() => {setSelectedScheduler(null); setEditableScheduler(null); setIsExistsReport(false)}} disabled={!editableScheduler} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<RestartAltOutlinedIcon />
								</IconButton>
							</Tooltip>
							
							<Tooltip title="START" variant="solid" placement="top">
								<IconButton onClick={startScheduler} disabled={isButtonPlayDisabled()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<PlayArrowOutlinedIcon />
								</IconButton>
							</Tooltip>

							<Tooltip title="STOP" variant="solid" placement="top">
								<IconButton onClick={stopScheduler} disabled={!stopCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<StopCircleOutlinedIcon />
								</IconButton>
							</Tooltip>

							<Tooltip title="EXECUTE ONE TIME" variant="solid" placement="top">
								<IconButton onClick={execOnce} disabled={!selectedScheduler && permission != "Full Control"} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#276CF2", height: "40px", width: "40px"}}>
									<PlayArrowRoundedIcon />
									<StopRoundedIcon sx={{marginLeft: "-10px"}} />
								</IconButton>
							</Tooltip>

							<Tooltip title="DELETE" variant="solid" placement="top">
								<IconButton onClick={() => setIsOpenDeletionConfirmationDialog(true)} disabled={!deleteCan()} variant="outlined" sx={{borderRadius: "12px", boxShadow: "1px 1px 2px 0px rgba(71, 71, 71, 0.2)", color: "#FF6C37", height: "40px", width: "40px"}}>
									<DeleteForeverOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Stack>

						<div style={{display: "flex", justifyContent: "end"}}>
							<Tooltip title="HIDE EDITOR" variant="solid" placement="top">
								<IconButton onClick={showEditor} sx={{...styleToolbarButton, marginTop: "4px"}}>
									<CloseOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
								</IconButton>
							</Tooltip>
						</div>
					</div>
					
					{/* Form */}
					<div style={{ margin: "12px 9px" }}>
					
						<FormControl required>
							<FormLabel>Name:</FormLabel>
							<Input placeholder="Name" value={editableScheduler?.name ?? ""} onChange={e => onEditScheduler("name", e.target.value)} />
						</FormControl>

						<FormControl required sx={{marginTop: "15px"}}>
							<FormLabel>Cron Expression:</FormLabel>
							<Tooltip title={cronTooltipText} variant="outlined" arrow placement="bottom">
								<Input size="md" placeholder="Cron Expression" value={editableScheduler?.cronExpression ?? ""} onChange={e => onEditScheduler("cronExpression", e.target.value)} />
							</Tooltip>
						</FormControl>
						
						<Button variant="outlined" color="neutral" startDecorator={isExistsReport ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} disabled={selectedScheduler?.isStarted} onClick={() => setIsShowReportWindow(value => !value)}>Report</Button>
					</div>
				</div>

				{/* Footer */}
				<div style={{gridArea: "footer", background: "#3B3D40", color: "white", fontSize: "10pt", padding: ".2rem 1rem .3rem 1rem", display: "flex", justifyContent: "space-between"}}>
					<div>User: {localStorage.getItem("login")}</div>
					<div>Permission: {permission}</div>
				</div>
			</div>

			<Modal open={isOpenDeletionConfirmationDialog} onClose={() => setIsOpenDeletionConfirmationDialog(false)}>
				<ModalDialog variant="outlined" role="alertdialog">
					<DialogTitle>
						<WarningRoundedIcon />
						Confirmation
					</DialogTitle>
					<Divider />
					<DialogContent>Are you sure you want to deleted schedule?</DialogContent>
					<DialogActions>
						<Button variant="solid" color="danger" onClick={() => {setIsOpenDeletionConfirmationDialog(false); deleteScheduler()}}>
							Yes
						</Button>
						<Button variant="plain" color="neutral" onClick={() => setIsOpenDeletionConfirmationDialog(false)}>
							No
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>

			{
				isShowSpinner != 0 &&

				<div style={{position: "absolute", width: "100%", height: "100dvh", top: 0, zIndex: 10}}>
					<CircularProgress color="primary" sx={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", "--CircularProgress-size": "100px", zIndex: "10"}}>
						<span style={{fontSize: "120%", color: "#3B3D40", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textWrap: "nowrap"}}>Please wait ...</span>
					</CircularProgress>
				</div>
			}

			{
				isShowReportWindow &&
				
				<Report moduleName={document.title}
					windowName="Reports"
					version={about?.version}
					isOpen={isShowReportWindow}
					closeCommand={() => setIsShowReportWindow(value => !value)}
					onSelect={onSelectReprot}
					onSelectCan={onSelectReportCan}
					scheduler={editableScheduler} />
			}

			<ToastContainer />
		</div>
	);
}