import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';

import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 28, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'18px', lineHeight:1 };
	const formControlStyle = { my: 1 };

	const [editedData, setEditedData] = React.useState({});
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'Task Effectivity'
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			setShowConfirm(true);
		} else if (type === 'Update') {
			setShowConfirm(true);
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () =>  {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};
	
	return (
		<Box>
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleUpdate} 
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			<Typography 
				sx={{
					position:'absolute',
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1,
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			textOverflow: 'ellipsis',
					width:`calc(100% - 9px)`
				}}
			>
				Aircraft Configuration Task Effectivity Editor:
			</Typography>
			<Box
				sx={{
					position:'absolute',
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			top:'25px',
					width:`calc(100% - 9px)`
				}}
			>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={editedData?.acConfigurationDescription ? false : true}
						onClick={()=>handleAction('Add')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow?.id && editedData?.acConfigurationDescription ? false : true}
						onClick={()=>handleAction('Update')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow?.id ? false : true}
						onClick={()=>handleAction('Delete')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
			</Box>
			
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					top:'70px',
					overflow:'auto'
				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1, minWidth:'535px', }}>					
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={{...labelStyle,alignSelf:'center'}}>AC Family:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={props.selectedACFamily ?? ''}
							/>
						</FormControl>
					</Grid>
					
					<Grid xs={11.8}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Aircraft Configuration Task Effectivity Description:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{...inputStyle,color:'#000078', height:100}}
								value={editedData?.acConfigurationDescription || ''}
								onChange={(event) => handleChange('acConfigurationDescription', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={11.8}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>Remarks:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{...inputStyle,color:'#000078', height:100}}
								value={editedData?.remarks || ''}
								onChange={(event) => handleChange('remarks', event.target.value)}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}