import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import AutocompleteListbox from '@mui/joy/AutocompleteListbox';

import { Popper } from '@mui/base/Popper';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import PartsAssyRegistrationWnd from './PartsAssyRegistrationWnd.tsx'
import PartsSubstitutionRegistrationWnd from './PartsSubstitutionRegistrationWnd.tsx'
import SelectAssyWnd from './SelectAssyWnd.tsx'

//#region Виртуализация списка pn 
function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number),
	};

	return (
		<AutocompleteOption {...dataSet[0]} style={inlineStyle}>
			{dataSet[1]}
		</AutocompleteOption>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return (
		<AutocompleteListbox
			{...props}
			{...outerProps}
			component="div"
			ref={ref}
			sx={{
				'& ul': {
					padding: 0,
					margin: 0,
					flexShrink: 0,
				},
			}}
		/>
	);
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	{
		anchorEl: any;
		open: boolean;
		modifiers: any[];
	} & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, anchorEl, open, modifiers, ...other } = props;
	const itemData: Array<any> = [];
	(
		children as [Array<{ children: Array<React.ReactElement<any>> | undefined }>]
	)[0].forEach((item) => {
		if (item) {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	});

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper ref={ref} anchorEl={anchorEl} open={open} modifiers={modifiers}>
		<OuterElementContext.Provider value={other}>
			<FixedSizeList
				itemData={itemData}
				height={itemSize * 8}
				width="100%"
				outerElementType={OuterElementType}
				innerElementType="ul"
				itemSize={itemSize}
				overscanCount={5}
				itemCount={itemCount}
			>
				{renderRow}
			</FixedSizeList>
		</OuterElementContext.Provider>
    </Popper>
  );
});
//#endregion

export default function AddEditeForm(props) {
	//Стили
	const filtersInputStyle = { borderRadius: 0, px:1, mx: 1, height: 26, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const filtersLabelStyle = { mx: 1, my: 0, p: 0, fontSize:'12px', alignSelf:'center' };
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px', alignSelf:'center' };
	const formControlStyle = { my: 0.5 };
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px'};
	
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [showAssyRegistrationWnd, setShowAssyRegistrationWnd] = React.useState(false)
	const [showAssySelectingWnd, setShowAssySelectingWnd] = React.useState(false)
	const [showSubstitationRegistrationWnd, setShowSubstitationRegistrationWnd] = React.useState(false)
	const [editedData, setEditedData] = React.useState({});
	const [editedPositionPn, setEditedPositionPn] = React.useState({})
	const [selectedPositionRow, setSelectedPositionRow] = React.useState({})
	const [selectedPositionPn, setSelectedPositionPn] = React.useState()
	const [selectedPositionPnAssy, setSelectedPositionPnAssy] = React.useState({})
	const [substitutionCount, setSubstitutionCount] = React.useState(0)
	const [positionEditedData, setPositionEditedData] = React.useState({})
	const [isTypeValid, setIsTypeValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	const [pnList, setPnList] = React.useState([])
	const [positionPnList, setPositionPnList] = React.useState([])
	const [filteredPositionPnList, setFilteredPositionPnList] = React.useState ([]);
	const [filters, setFilters] = React.useState({pn:'', description:'', acType:''});
	const [positionsList, setPositionsList] = React.useState([{position:''}])
	const [positionTime, setPositionTime] = React.useState([{positionTime:''}])

	//Запрос списка PN
	const fetchPnList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/Pns(pn='',description='')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setPnList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Запрос description для выбранного pn
	React.useEffect(() => {
		const fetchPnDescription = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/Desc/${editedData.pn}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				handleChange('pnDescription', result.description ?? '')
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(editedData.pn) {
			fetchPnDescription()
		} else {
			handleChange('pnDescription','')
		}
	}, [editedData?.pn]);

	//Запрос assyOrPart для выбранного position pn
	React.useEffect(() => {
		const fetchPositionPnAssyOrPart = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsAssies/AssyOrPart/${selectedPositionPn}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				//handleChange('pnDescription', result.description ?? '')
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(selectedPositionPn) {
			fetchPositionPnAssyOrPart()
		} 
	}, [selectedPositionPn]);

	//Запрос списка PN для Position Editor
	const fetchPnListForPositionEditor = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogAcTypes/GetWithAcType(partNumber='',description='')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setPositionPnList(result)
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Запрос данных о выбранном position по id
	React.useEffect(() => {
		const fetchPositionData = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelPositions/${props.selectedPosition}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setEditedData(result)
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(props.selectedPosition !== 0) fetchPositionData()
	}, [props.selectedPosition]);

	//Запрос Position Pn и чекбоксов is PP, is APU etc.
	React.useEffect(() => {
		const fetchPositionData = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelPns/PartEffectivity(ampId=${props.selectedAmp.id},positionId=${props.selectedPosition})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				let result = await response.json();
				result = result.filter(item => item.mainPn === "Y")[0]
				setEditedPositionPn(result)
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(props.selectedPosition !== 0) fetchPositionData()
	}, [props.selectedPosition]);

	//Запрос списка positions для position editore
	const fetchPositionsList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonPositions?$select=position`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setPositionsList(result.value);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Запрос данных для нижней области чекбоксов (TSN, TSO, TSI etc.)
	React.useEffect(() => {
		const fetchTimeController = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelTimes?$filter=positionId eq ${editedData?.id} and ampId eq ${props.selectedAmp.id} &select=positionTime`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setPositionTime(result.value)
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(editedData?.id) fetchTimeController()
	}, [editedData?.id]);

	React.useEffect(() => {
		fetchPnList();
		fetchPositionsList();
		fetchPnListForPositionEditor();
	}, []);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		//fetchMeta();
	}, []);

	//Запрос проверки валидности ATA
	const checkValidType = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/ValidType()?type='${editedData.type}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsTypeValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Фильтрация данных
	React.useEffect(() => {
		let filtered = positionPnList;
        for (const key in filters) {
			if(filters[key] != '') {
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredPositionPnList(filtered);
		
		//Сброс выделения строки, если она не попадает в отфильтрованные данные
		//if(!filtered.some(obj => obj.id === selectedRow.id)){
			//setSelectedRow({id:0})
		//}
    }, [positionPnList, filters]);

	//Изменение фильтров
	const handleChangeFilters = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Обработчик события добавления новой записи
	const handleAdd = async () => {
		if (await checkValidType()) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : ''
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.type === props.selectedPosition || await checkValidType()){
			setIsTypeValid(true);
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/${props.selectedPosition .id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						...editedData,
						manufacturer:editedData.family ? props.engineFamilies.find(data => data.family === editedData.family)?.manufacturer : '',
						numOfFanBlades:props.selectedPosition .numOfFanBlades
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/${props.selectedPosition .id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}

	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		//const maxLengthDefault = 255;
		//let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		//if (value.length > maxLength) return;	

		setEditedData({ ...editedData, [property]: value });
	};
	const tableRef = React.useRef();
	
	return (
	<>
		{showAssyRegistrationWnd &&(
			<PartsAssyRegistrationWnd setOpen={setShowAssyRegistrationWnd} URL={props.URL}/>
		)}
		{showSubstitationRegistrationWnd &&(
			<PartsSubstitutionRegistrationWnd setOpen={setShowSubstitationRegistrationWnd} URL={props.URL}/>
		)}
		{showAssySelectingWnd &&(
			<SelectAssyWnd setOpen={setShowAssySelectingWnd} URL={props.URL} ampId={props.selectedAmp.id}/>
		)}
		<Box
			id='editorArea'
			sx={{
				position:'absolute',
				height:'100%',
				width:'100%',
				display:'grid',
				gridTemplateRows:'30% 70%'
			}}
		>
			<Box
				sx={{
					position:'relative',
					height:`100%`,
					width:'100%',
				}}
			>
				<Typography 
					sx={{
						fontSize:'16px',
						fontWeight:'bold',
						color:'#000078',
						fontFamily:'Arial, sans-serif',
						ml:1
					}}
				>
					Part Effectivity Editor (for Selected IPC Position):
				</Typography>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={true}
						onClick={handleAdd}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleUpdate}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleDelete}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>

				<Box
					sx={{
						position:'absolute',
						height:`calc(100% - 80px)`,
						overflow:'auto'
					}}
				>
					<Grid container spacing={0} sx={{ flexGrow: 1 }}>
						<Grid xs={5}>
							<FormControl sx={formControlStyle} required>
								<FormLabel sx={labelStyle}>PN:</FormLabel>
								<Autocomplete
									disableClearable
									slots={{
										listbox: ListboxComponent,
									}}
									options={pnList}
									renderOption={(props, option) => [props, option] as React.ReactNode}
									value={{ label: editedData?.pn ?? ''}}
									onChange={(event, value) => handleChange('pn', value ?? '')}
									//onKeyDown={(event) => handleAdditionalInfo(event, 'mfrCode')}
									sx={{
										...inputStyle,
										backgroundColor:'#c6ffff',
									}}
								/>
							</FormControl>
						</Grid>
						<Grid xs={7}>
							<FormControl sx={formControlStyle} required>
								<FormLabel sx={labelStyle}>Description:</FormLabel>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									value={editedData.pnDescription ?? ''}
								/>
							</FormControl>
						</Grid>

						<Grid xs={3} sx={{mt:2}}>
							<Box
								sx={{
									display: 'flex',
								}}
							>
								<Typography sx={labelStyle}>
									Preferable:
								</Typography>
								<Checkbox
									variant='outlined'
									sx={{
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										},              
									}}
								/>
							</Box>
						</Grid>
						<Grid xs={3}/>
						<Grid xs={6} sx={{mt:2,display:'flex', justifyContent:'flex-end'}}>
							<Button		
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									mx:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Part Catalog
							</Button>
							<Button		
								color='neutral'
								onClick={() => handleChange('pn','')}
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									mx:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Refresh PN
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box
				sx={{
					position:'relative',
					height:`100%`,
					width:'100%',
				}}
			>
				<Box 
					sx={{
						display:'flex',
						flexDirection:'row'
					}}
				>
					<Typography 
						sx={{
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							ml:1,
							
						}}
					>
						Position Editor:
					</Typography>
					<Button		
						color='neutral'
						sx={{
							borderRadius:0,
							height: 26, 
							minHeight: 0,
							fontWeight:100,
							ml:'auto',
							bgcolor:'#c7c3fe',
							color:'black',
							border:'2px outset grey',
							display:selectedPositionPn?'flex':'none'
						}}
						onClick={()=>setShowAssyRegistrationWnd(!showAssyRegistrationWnd)}
					>
						Assy
					</Button>
					<Button		
						color='neutral'
						sx={{
							borderRadius:0,
							height: 26, 
							minHeight: 0,
							fontWeight:100,
							mx:1,
							ml:0,
							bgcolor:'#c7c3fe',
							color:'black',
							border:'2px outset grey',
							display:selectedPositionPn?'flex':'none'
						}}
						onClick={()=>setShowSubstitationRegistrationWnd(!showSubstitationRegistrationWnd)}
					>
						Substitation - 0
					</Button>
				</Box>
				<Box
					sx={{
						position:'absolute',
						height:`calc(100% - 34px)`,
						width:'100%',
						overflow:'auto'
					}}
				>
					<Grid container spacing={0} sx={{ flexGrow: 1 }}>
						<Grid xs={12} sx={{m:1,}}>
							<Box
								sx={{
									width:'100%',
									height:150,
									border:'1px solid gray',
									position:'relative',
								}}
							>
								<Typography 
									sx={{
										position:'absolute',
										top:'-7px',
										fontSize:12,
										fontWeight:800,
										color:'black',
										bgcolor:'#d6d3ce',
										lineHeight:1,
										ml:1
									}}
								>
									Select PN, Description
								</Typography>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={6}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Filter PN:</FormLabel>
											<Input 
												sx={{
													...filtersInputStyle, 
													backgroundColor:'#c6ffff',
												}} 
												value={filters?.pn || ''}
												onChange={(event) => handleChangeFilters('pn', event.target.value)}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Filter Description:</FormLabel>
											<Input 
												sx={{
													...filtersInputStyle, 
													
												}} 
												value={filters?.description || ''}
												onChange={(event) => handleChangeFilters('description', event.target.value)}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={filtersLabelStyle}>Filter AC Type:</FormLabel>
											<Autocomplete
												options={[...new Set(positionPnList.map(data => data?.acType))].map(acType => ({ label: acType || '' }))}
												sx={{...inputStyle, backgroundColor:'#c6ffff'}}
												onChange={(event, value) => handleChangeFilters('acType', value ? value.label : '')}
												//onKeyDown={(event) => handleAdditionalInfo(event, 'sta')}
												value={filters.acType === '' ? null : { label: filters.acType }}
											/>
										</FormControl>
									</Grid>
									<Grid xs={12}>
										<Box
											sx={{
												height:'90px',
												mx:1,
												borderRadius:'0px',
												bgcolor:'#c6ffff',
											}}
										>
											<AutoSizer>
												{({ height, width }) => (
													<Table
														gridStyle={{ outline: 'none' }}
														width={width}
														height={height}
														headerHeight={0}
														rowHeight={20}
														rowCount={filteredPositionPnList.length}
														rowGetter={({ index }) => filteredPositionPnList[index]}
														onRowClick={({ index })=>setSelectedPositionRow(filteredPositionPnList[index])}
														onRowDoubleClick={({ index })=>setSelectedPositionPn(filteredPositionPnList[index].pn)}
													>
														<Column width={width} dataKey="pn" />
														<Column width={width} dataKey="description" />
													</Table>
												)}
											</AutoSizer>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid xs={12}>
							<Tooltip title='Add'>
								<IconButton
									variant='plain'
									disabled={true}
									onClick={handleAdd}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px' 
										src="/images/svg/add.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Update'>
								<IconButton 
									variant='plain'
									disabled={true}
									onClick={handleUpdate}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px' 
										src="/images/svg/save.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Delete'>
								<IconButton 
									variant='plain'
									disabled={true}
									onClick={handleDelete}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px'  
										src="/images/svg/trashcan.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Assemble'>
								<IconButton 
									variant='plain'
									//disabled={props.selectedRow.id ? false : true}
									onClick={()=>setShowAssySelectingWnd(!showAssySelectingWnd)}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px'  
										src="/images/svg/assy.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Dis Assemble'>
								<IconButton 
									variant='plain'
									disabled={true}
									onClick={()=>setShowAssySelectingWnd(!showAssySelectingWnd)}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px'  
										src="/images/svg/disAssy.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title='Refresh Editor'>
								<IconButton 
									variant='plain'
									disabled={true}
									onClick={()=>setShowAssySelectingWnd(!showAssySelectingWnd)}
									sx={{
										m:0,
										'&:disabled img': {
											opacity: 0.5,
										}
									}}
								>
									<img 
										width='35px' 
										height='35px'  
										src="/images/svg/refresh.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid xs={7}>
							<FormControl required>
								<FormLabel sx={labelStyle}>Position PN:</FormLabel>
								<Input 
									disabled
									sx={{
										...inputStyle, 
										'--variant-outlinedDisabledColor':'black',
										'--variant-outlinedDisabledBorder':'black',
										bgcolor:'#d6d3ce',
									}} 
									value={editedPositionPn?.pn ?? ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FIN:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									value={editedData?.fin || ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={6}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>IPC Position:</FormLabel>
								<Box
									sx={{
										display:'flex'
									}}
								>
									<Input 
										sx={{
											...inputStyle, 
										}} 
										value={editedData?.ipcPosition?.split('-')[0] || ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										value={editedData?.ipcPosition?.split('-')[1] || ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										value={editedData?.ipcPosition?.split('-')[2] || ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										value={editedData?.ipcPosition?.split('-')[3] || ''}
									/>
									-
									<Input 
										sx={{
											...inputStyle, 
										}} 
										value={editedData?.ipcPosition?.split('-')[4] || ''}
									/>
								</Box>
							</FormControl>
						</Grid>
						<Grid xs={6}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>I/R AMM Reference:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									value={editedData?.riAmmReference || ''}
								/>
							</FormControl>
						</Grid>
						
						<Grid xs={9}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Position Description:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									value={editedData?.description || ''}
								/>
							</FormControl>
						</Grid>
						<Grid xs={3}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Position:</FormLabel>
								<Autocomplete
									options={positionsList.map(data => ({ label: data.position }))}
									value={{ label: editedData?.position ?? ''}}
									//onChange={(event, value) => handleChange('family', value ? value.label : '')}
									//onKeyDown={(event) => handleAdditionalInfo(event, 'family')}
									sx={{
										...inputStyle,
									}}
								/>
							</FormControl>
						</Grid>
						<Grid xs={12}>
							<Box sx={{
								display: 'flex',
								alignItems:'center',
								justifyContent: 'space-between',
								mx:1
							}}>
								<Tooltip title='Position is Engine (Power Plant) Part Number'>
									<Checkbox 
										label="is PP:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isEngine}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isEngine: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is APU Part Number'>
									<Checkbox 
										label="is APU:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isApu}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isApu: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Main Landing Gear Part Number'>
									<Checkbox 
										label="is MLG:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isMlg}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isMlg: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Nose Landing Gear Part Number'>
									<Checkbox 
										label="is NLG:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isNlg}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isNlg: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Propeller Part Number'>
									<Checkbox 
										label="is PROP:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isPropeller}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isPropeller: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Main GearBox Part Number'>
									<Checkbox 
										label="is MGBX:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isMgbx}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isMgbx: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Tail GearBox Part Number'>
									<Checkbox 
										label="is TGBX:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isTgbx}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isTgbx: event.target.checked })}
									/>
								</Tooltip>
								<Tooltip title='Position is Structure Part Number'>
									<Checkbox 
										label="is Strct:"
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedPositionPn?.isStruct}
										onChange={(event) => setEditedPositionPn({ ...editedPositionPn, isStruct: event.target.checked })}
									/>
								</Tooltip>
								
							</Box>
						</Grid>
						<Grid xs={12}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>
									Remarks:
								</FormLabel>
								<Textarea
									maxRows={10}
									value={editedData?.remarks || ''}
									sx={{
										...inputStyle, 
										height: '70px', 
										lineHeight: '1.2'
									}}
									onChange={(event) => handleChange('remarks', event.target.value)}
								>
								</Textarea>
							</FormControl>
						</Grid>

						<Grid xs={2}></Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since New'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSN:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since New'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'TSN')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Overhaul'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSO:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Overhaul'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'TSO')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Installation'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSI:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Installation'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'TSI')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Repair (Restoration)'>
								<Typography sx={{color:'black',fontSize:16}}>
									TSR:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Time Since Repair (Restoration)'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'TSR')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Time'>
								<Typography sx={{color:'black',fontSize:16}}>
									TAPU:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Time'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'TAPU')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						
						<Grid xs={2}></Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since New'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSN:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since New'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'CSN')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Overhaul'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSO:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Overhaul'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'CSO')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Installation'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSI:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Installation'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'CSI')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Repair (Restoration)'>
								<Typography sx={{color:'black',fontSize:16}}>
									CSR:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='Cycle Since Repair (Restoration)'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'CSR')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Cycle'>
								<Typography sx={{color:'black',fontSize:16}}>
									CAPU:
								</Typography>
							</Tooltip>
						</Grid>
						<Grid xs={1}>
							<Tooltip title='APU Cycle'>
								<Checkbox 
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={positionTime?.some(item => item.positionTime === 'CAPU')}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>
					</Grid>

				</Box>
			</Box>
		</Box>
		</>
	);
}
