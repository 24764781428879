import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Tooltip from '@mui/joy/Tooltip';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';

import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 28, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'18px', alignSelf:'center', lineHeight:1, fontWeight:'100' };
	const formControlStyle = { my: 1 };
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',display:'flex', justifyContent:'flex-start'};

	const [editedData, setEditedData] = React.useState({});
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'Check'
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	//Запрос данных для выбранной записи
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/${props.selectedRow}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setEditedData(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		if(props?.selectedRow) {
			fetchData();
		}
	}, [props?.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			setShowConfirm(true);
		} else if (type === 'Update') {
			setShowConfirm(true);
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () =>  {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/${props.selectedRow}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/${props.selectedRow}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	const IntervalEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Interval:
					</Typography>
					<Typography
						sx={{
							ml:3,
							bgcolor:'#d6d3ce',
							color:'red'
						}}
					>
						*
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100 }}>
					<Grid container spacing={0} xs={9}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarInterval === 'DY' ?? false}
										onChange={() => handleChange('calendarInterval', 'DY')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarInterval === 'MO' ?? false}
										onChange={() => handleChange('calendarInterval', 'MO')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarInterval === 'YR' ?? false}
										onChange={() => handleChange('calendarInterval', 'YR')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhInterval ?? ''}
									onChange={(event) => handleChange('fhInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcInterval ?? ''}
									onChange={(event) => handleChange('fhInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueInterval ?? ''}
									onChange={(event) => handleChange('calendarValueInterval', event.target.value)}
								/>
							</FormControl>
						</Grid>			
					</Grid>
				</Grid>
			</Box>
		)
	}

	const StartThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Start Threshold:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100 }}>
					<Grid container spacing={0} xs={9}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarStart === 'DY' ?? false}
										onChange={() => handleChange('calendarInterval', 'DY')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarStart === 'MO' ?? false}
										onChange={() => handleChange('calendarInterval', 'MO')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarStart === 'YR' ?? false}
										onChange={() => handleChange('calendarInterval', 'YR')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhStart ?? ''}
									onChange={(event) => handleChange('fhStart', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcStart ?? ''}
									onChange={(event) => handleChange('fcStart', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueStart ?? ''}
									onChange={(event) => handleChange('calendarValueStart', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const FinishThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Finish Threshold:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100 }}>
					<Grid container spacing={0} xs={9}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarFinish === 'DY' ?? false}
										onChange={() => handleChange('calendarFinish', 'DY')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarFinish === 'MO' ?? false}
										onChange={() => handleChange('calendarFinish', 'MO')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.calendarFinish === 'YR' ?? false}
										onChange={() => handleChange('calendarFinish', 'YR')}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fhFinish ?? ''}
									onChange={(event) => handleChange('fhFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.fcFinish ?? ''}
									onChange={(event) => handleChange('fcFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.calendarValueFinish ?? ''}
									onChange={(event) => handleChange('calendarValueFinish', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const ToleranceEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tolerance:
					</Typography>
					<Typography
						sx={{
							ml:3,
							bgcolor:'#d6d3ce',
							color:'red',
						}}
					>
						*
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100 }}>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Early Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.earlyReschedMethod === 'Completion'}
										onChange={() => handleChange('earlyReschedMethod', 'Completion')}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.earlyReschedMethod === 'When due'}
										onChange={() => handleChange('earlyReschedMethod', 'When due')}
									/>
								</Tooltip>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Late Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.lateReschedMethod === 'Completion'}
										onChange={() => handleChange('lateReschedMethod', 'Completion')}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData?.lateReschedMethod === 'When due'}
										onChange={() => handleChange('lateReschedMethod', 'When due')}
									/>
								</Tooltip>
						</Grid>

					</Grid>
				</Grid>
			</Box>
		)
	}
	
	return (
		<Box>
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleUpdate} 
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			<Typography 
				sx={{
					position:'absolute',
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1,
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			textOverflow: 'ellipsis',
					width:`calc(100% - 9px)`
				}}
			>
				Model's Maintenance Checks Editor:
			</Typography>
			<Box
				sx={{
					position:'absolute',
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			top:'20px',
					width:`calc(100% - 9px)`
				}}
			>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={editedData?.id && editedData?.checkDescription ? false : true}
						onClick={()=>handleAction('Add')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow && editedData?.id && editedData?.checkDescription ? false : true}
						onClick={()=>handleAction('Update')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow ? false : true}
						onClick={()=>handleAction('Delete')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
			</Box>
			
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					top:'40px',
					overflow:'auto'
				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1, minWidth:'535px', }}>
					<Grid xs={0.2}/>
					<Grid xs={10} sx={{display:'flex', alignItems:'center', mt:3}}>
						<Radio 
							checked={true}
							value="Task" 
							label="Cyclic Model:" 
							variant="outlined" 
							color='neutral'
							sx={{
								'--Radio-size':'16px',
								gap:0.5,
								fontSize:16
							}}
						/>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Check ID:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.check ?? ''}
								onChange={(event) => handleChange('check', event.target.value)}
							/>
						</FormControl>
					</Grid>
					
					<Grid xs={12}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Check Description:</FormLabel>
							<Textarea 
								maxRows={1}
								defaultValue={props.selectedRow?.checkDescription}
								sx={{...inputStyle,color:'#000078', height:60}}
								value={editedData?.checkDescription ?? ''}
								onChange={(event) => handleChange('checkDescription', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={4}>
						<Tooltip title='Major Check - use for AC Status report only'>
							<Checkbox 
								variant="outlined"
								color="neutral"
								label=':Major Check'
								sx={{
									fontSize:'18px',
									'--Checkbox-size':'15px',
									gap:0.4,
									ml:0.4,
									color:'#3c0203',
									fontWeight:'bold',
									'& span': {
										borderRadius: '0px',
										borderWidth:'1px',
										borderColor:'black',
										color:'black'
									}, 
								}}
								checked={editedData?.isMajorCheck}
								onChange={(event) => handleChange('isMajorCheck', event.target.checked)}
							/>
						</Tooltip>
					</Grid>

					<Grid xs={12} sx={{mt:3}}>
						<Tabs
							//value={selectedTab}
							//onChange={(event, newValue) => handleChangeTab(newValue)}
							defaultValue="Int"
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								mx:0.6,
								'--Tabs-spacing': '5px'
							}}
						>
							<TabList >
								<Tooltip title='Interval'>
									<Tab value="Int" sx={tabStyle}>
										Interval
									</Tab>
								</Tooltip>
								<Tooltip title='Start Threshold'>
									<Tab value="ST" sx={tabStyle}>
										Start Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Finish Threshold'>
									<Tab value="FT" sx={tabStyle}>
										Finish Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Tolerance Method'>
									<Tab value="T" sx={tabStyle}>
										Tolerance
									</Tab>
								</Tooltip>
							</TabList>
							<TabPanel value="Int" sx={{p:'0px'}}>
								<IntervalEditore/>
							</TabPanel>
							<TabPanel value="ST" sx={{p:'0px'}}>
								<StartThresholdEditore/>
							</TabPanel>
							<TabPanel value="FT" sx={{p:'0px'}}>
								<FinishThresholdEditore/>
							</TabPanel>
							<TabPanel value="T" sx={{p:'0px'}}>
								<ToleranceEditore/>
							</TabPanel>
						</Tabs>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}