import { AmpPosIcons } from "./Dictionaries/AmpPosIcons.ts";
import { AmpPositionsIconContext } from "./Context/AmpPositionsIconContext.ts";
import { Interpreter } from "../../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";

export class AmpReplationIconInterpreter extends Interpreter<AmpPositionsIconContext> {
    public Interpret(context: AmpPositionsIconContext): ITreeViewIcon | undefined {
        if (context.isRootProject)
            return AmpPosIcons.ReportModelProject;
        if (context.isRootPlane)
            return AmpPosIcons.PlaneVertical;

        if (this.yellowBoxCondition(context.ipcPos))
            return AmpPosIcons.YellowBox;

        if (!this.isHBox(context.mpExists)) {
            if (this.isSingleBoxStatus(context.assyStatus))
                return AmpPosIcons.BlueBox;
            else if (this.isDoubleBoxStatus(context.assyStatus))
                return AmpPosIcons.BlueDoubleBox;
        }

        return undefined;
    }

    private isHBox(mpExists : string | null) {
        return !!mpExists;
    }

    private isSingleBoxStatus(assyStatus: string | null): boolean {
        return (assyStatus == 'NA'
            || assyStatus == 'LP'
            || assyStatus == 'LALP');
    }

    private isDoubleBoxStatus(assyStatus: string | null): boolean {
        return (assyStatus == 'MA'
            || assyStatus == 'LA');
    }

    private yellowBoxCondition(ipcPos : string | null): boolean {
        return (!ipcPos);
    } 
}