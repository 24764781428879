import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import Spiner from '../../../Components/Spiner.tsx'

export default function MRB_Category(props) {

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[{id:'acFamily',name:'AC_Family'}, {id:'mrbCode',name:'MRB_Code'}, {id:'mrbCodeDescription',name:'MRB_Code_Description'}, {id:'ampId',name:'AMP_ID'}]

	const [data, setData] = React.useState<{id:number, acFamily:string, mrbCode:string, mrbCodeDescription:string, ampId:number}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, acFamily:string, mrbCode:string, mrbCodeDescription:string, ampId:number}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState<{id:number, mrbCode:string, mrbCodeDescription:string}>();
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	const [showSpinner, setShowSpinner] = React.useState(0)

	//Запрос данных для таблицы
	const fetchData = async () => {
		setShowSpinner(value => value + 1);
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes?$filter=acFamily eq '${props.selectedACFamily}' and ampId eq ${props.selectedAmp?.id}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const sortedResult =result.value.sort((a, b) => b.id - a.id);
			setData(sortedResult);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
		setShowSpinner(value => value - 1);
	};

	React.useEffect(() => {
		if(props?.selectedAmp?.id) fetchData();
	}, [props?.selectedAmp?.id]);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row?.id === selectedRow?.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);

		//Сброс выделения строки, если она не попадает в отфильтрованные данные
		if(!filtered.some(obj => obj?.id === selectedRow?.id)){
			setSelectedRow({id:0})
		}

    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters(undefined);
		setSelectedRow({id:0});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		if (filters === undefined) {
			return true;
		}
	
		// Проверяем, все ли свойства объекта пустые
		return Object.values(filters).every(value => 
			value === '' || value === null || value === undefined || 
			(typeof value === 'number' && value === 0)
		);
	}

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//#region Изменение ширины AddEditForm
	const [isDragging, setIsDragging] = React.useState(false);
	const [defWidth, setDefWidth] = React.useState(600)
  	const [addEditFormWidth, setAddEditFormWidth] = React.useState(defWidth);
	const [startPosition, setStartPosition] = React.useState({ x: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ x: e.clientX});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaX = e.clientX - startPosition.x;
		const newWidth = defWidth-deltaX;
		if (newWidth >= 10) {
			setAddEditFormWidth(newWidth )
		} else {
			return;
		}
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefWidth(addEditFormWidth)
	};
	//#endregion

	const rowRefs = React.useRef([]);

	const handleFocusRow = () => {
        const selectedRowIndex = filteredData.findIndex(row => row?.id === selectedRow?.id);
        if (selectedRowIndex !== -1 && rowRefs.current[selectedRowIndex]) {
            rowRefs.current[selectedRowIndex].focus();
        }
    };

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			{showSpinner !== 0 && (<Spiner/>)}
			<Box
				id='main'
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?`auto 10px ${addEditFormWidth}px`:'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
						minWidth:'290px'
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							whiteSpace: 'nowrap', 
							overflow: 'hidden',    
							textOverflow: 'ellipsis',
							width:`calc(100% - 9px)`,
							left:16
						}}
					>
						MRB Category Codes:
					</Typography>
					<Box
						id='buttons'
						sx={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							position:'absolute',
							right:16,
							height:40
						}}
					>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor: checkFilters() ? 'transparent':'#DDE7EE',
							position:'absolute',
							width: `calc(100% - 32px)`,
							top:'42px',
							left:'16px'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 96px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							mx:'16px',
							mt:'66px',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.id ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{ headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters?.id || ''}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}></th>
										<th style={{padding:0}}>
											<Input
												value={filters?.mrbCode || ''}
												onChange={(event) => handleChange('mrbCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.mrbCodeDescription || ''}
												onChange={(event) => handleChange('mrbCodeDescription', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#c0c0ff': index % 2 === 0 ? '#EDF2F7' : 'white',
											color: 'black',
										}}
										ref={el => (rowRefs.current[index] = el)}
                            			tabIndex={0}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#c0c0ff' :'#c6c3c6'}}>{row.id}</td>
										<td style={rowStyle}>{row.acFamily}</td>
                                		<td style={rowStyle}>{row.mrbCode}</td>
										<td style={rowStyle}>{row.mrbCodeDescription}</td>
										<td style={rowStyle}>{row.ampId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
					<Box
						sx={{
							display: 'flex',
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`20px`,
							bottom:5,
							mx:'16px',
							alignItems:'center',
							justifyContent:'space-between',
						}}
					>
						{filteredData?.length !== 0 ? (
							<Typography
								sx={{
									fontSize:'12px',
								}}
							>
								Found {filteredData?.length} Records
							</Typography>
						):(
							<Typography
								sx={{
									fontSize:'12px',
								}}
							>
								No Records were Found!
							</Typography>
						)}
					</Box>
				</Box>
				<Box
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						cursor:'ew-resize',
						position:'relative',
						display:showEditor?'flex':'none',
					}}
				>
					<Divider 
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						orientation="vertical" 
						sx={{
							cursor:'ew-resize',
							position:'absolute',
							height:'100%'
						}}
					/>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedRow={selectedRow} 
						setSelectedRow={setSelectedRow}
						reloadTable={fetchData}
						focusRow={handleFocusRow}
						setResponseStatus={setResponseStatus}
						selectedACFamily={props.selectedACFamily}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
